import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { globalTeamUri } from "@smart/bridge-types-basic";
import { extractId } from "@smart/itops-types-basic";
import { ContextMenu } from "@smart/itops-ui-dom";
import {
  useToggleSlug,
  useUpdateFormOwnership,
} from "@smart/manage-gql-client-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { GqlForm } from "../types";

export type FormMenuProps = {
  form: GqlForm;
  setDeletingForm: (form: GqlForm) => void;
  setDuplicatingForm: (form: GqlForm) => void;
};

export const FormMenu = ({
  form,
  setDeletingForm,
  setDuplicatingForm,
}: FormMenuProps) => {
  const { permission } = useUser();
  const { globalForm } = useFeatureFlags();
  const [updateSlug, toggleSlug] = useToggleSlug();
  const [updateFormOwnership] = useUpdateFormOwnership();
  const navigate = useNavigate();

  const isGenerating =
    form.aiUserFeedback?.status === "generating" ||
    form.aiUserFeedback?.status === "generated";
  const canActivate =
    !isGenerating &&
    permission?.can("update", {
      type: "slug",
      value: {
        uri: form.rawForm.slug?.uri || "",
        active: form.active ?? false,
        formUri: form.uri,
      },
      parentForm: {
        uri: form.uri,
        teamUri: form.teamUri,
        active: form.active,
      },
    });
  const canView = permission?.can("view", {
    type: "form",
    value: { uri: form.uri, teamUri: form.teamUri, active: form.active },
  });
  const canDuplicate = canView && !isGenerating;
  const canDelete = permission?.can("delete", {
    type: "form",
    value: { uri: form.uri, teamUri: form.teamUri, active: form.active },
  });
  const canMoveToGlobal =
    form.teamUri !== globalTeamUri &&
    permission?.can("add", {
      type: "form",
      value: { uri: form.uri, teamUri: globalTeamUri },
    });

  return (
    <ContextMenu
      button={{
        icon: { library: "lucide", name: "MoreVertical" },
        kind: "borderless",
      }}
      items={[
        {
          text: "Open form",
          icon: { library: "lucide", name: "Eye" },
          onClick: () => navigate(`/embedded/builder/${extractId(form.uri)}`),
        },
        {
          text: "Active",
          toggled: form.active ?? false,
          disabled: toggleSlug.loading,
          hidden: !canActivate,
          keepOpen: true,
          onClick: () =>
            updateSlug({
              variables: {
                formUri: form.uri,
                fields: { active: !form.active },
              },
            }),
        },
        {
          text: "Duplicate form",
          icon: { library: "lucide", name: "Copy" },
          onClick: () => setDuplicatingForm(form),
          hidden: !canDuplicate,
          divider: "top",
        },
        {
          text: "Delete form",
          icon: { library: "lucide", name: "Trash2" },
          schemeColor: ["red", "r100"],
          hidden: !canDelete,
          onClick: () => setDeletingForm(form),
        },
        {
          text: "Move to global forms",
          icon: { library: "lucide", name: "LibraryBig" },
          hidden: !globalForm || !canMoveToGlobal,
          onClick: () =>
            updateFormOwnership({
              variables: { uri: form.uri, teamUri: globalTeamUri },
            }),
          divider: "top",
        },
      ]}
    />
  );
};
