import styled from "@emotion/styled";

import { fileTypes } from "@smart/bridge-images-dom";
import { Icon } from "@smart/itops-icons-dom";
import { Variant, variantStyle } from "@smart/itops-ui-dom";
import { isKeyOf } from "@smart/itops-utils-basic";
import { useLoadMatterFiles } from "@smart/manage-gql-client-dom";

const FileItemWrapper = styled.div<{ isSelected: boolean; isFolder: boolean }>`
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;

  background-color: ${(props) =>
    props.isSelected && props.theme.scheme.blue.r40};

  &:hover {
    background-color: ${(props) =>
      !props.isSelected && props.theme.scheme.blue.r10};
  }

  .file-type {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: ${(props) => props.theme.baseUnit * 2.5}rem;
    height: ${(props) => props.theme.baseUnit * 2.5}rem;
    margin-right: ${(props) => props.theme.baseUnit * 0.2}rem;

    flex: 0 0 ${(props) => props.theme.baseUnit * 2.5}rem;
  }

  span {
    line-height: 2.5rem;
    text-decoration: ${(props) => (props.isFolder ? "underline" : "none")};
  }
`;

const FolderIconWrapper = styled.div<{ variant: Variant }>`
  ${variantStyle}
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
`;

export type FileItemType = NonNullable<
  ReturnType<typeof useLoadMatterFiles>["result"]
>[number];

type FileItemProps = {
  fileItem: FileItemType;
  selectedFileItem: FileItemType | undefined;
  setSelectedFileItem: (selected: FileItemType) => void;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
};

export const FileItem = ({
  fileItem,
  selectedFileItem,
  setSelectedFileItem,
  setViewingFolderId,
}: FileItemProps) => {
  const extension = (fileItem?.fileExtension || "").replace(".", "");
  const isFolder = fileItem.type === "folder";

  return (
    <FileItemWrapper
      isFolder={isFolder}
      isSelected={selectedFileItem?.id === fileItem.id}
      onClick={() => {
        if (isFolder) {
          setViewingFolderId(fileItem.id);
        } else {
          setSelectedFileItem(fileItem);
        }
      }}
    >
      {isFolder ? (
        <FolderIconWrapper variant="highlightYellow">
          <Icon library="lucide" name="Folder" size={15} />
        </FolderIconWrapper>
      ) : (
        <div
          className="file-type"
          style={{
            backgroundImage: `url(${isKeyOf(fileTypes)(extension) ? fileTypes[extension] : fileTypes.txt})`,
          }}
        />
      )}
      <span>{fileItem.name}</span>
    </FileItemWrapper>
  );
};
