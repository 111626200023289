import { Modal } from "@smart/itops-ui-dom";

type ClearFormProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
};
export const ClearForm = ({ open, onSubmit, onClose }: ClearFormProps) => (
  <Modal
    header={{
      icon: {
        library: "lucide",
        name: "AlertCircle",
        variant: "header",
      },
      text: "Clear Form?",
    }}
    footer={{
      left: [
        {
          text: "Cancel",
          variant: "cancel",
          onClick: onClose,
        },
      ],
      right: [
        {
          text: "Clear form",
          variant: "save",
          onClick: onSubmit,
        },
      ],
    }}
    open={open}
    onClose={onClose}
    closeOptions={{
      escapeKey: false,
      clickOutside: false,
    }}
  >
    <p>
      This will remove any answers entered into the form and can not be undone.
    </p>
    <p>Are you sure you want to clear it?</p>
  </Modal>
);
