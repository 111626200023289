import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { bytesPerMegaByte } from "@smart/bridge-types-basic";
import { FailureMessage, Modal, RequiredAsterisk } from "@smart/itops-ui-dom";
import { fromEntries } from "@smart/itops-utils-basic";
import {
  useLoadMatterFiles,
  useUploadQuestionGenerationFiles,
} from "@smart/manage-gql-client-dom";

import { FileItem, FileItemType } from "./file-item";
import { FolderInfo, FolderPathDisplay } from "./folder-path-display";

const availableFileExtensions = [".png", ".jpg", ".jpeg", ".pdf"];
const maxFileSizeMB = 5;

const SelectFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .file-items {
    border: 1px solid ${(props) => props.theme.scheme.grey.r40};
    border-radius: ${(props) => props.theme.baseUnit * 0.5}rem;
    overflow: hidden;

    .file-item-list {
      height: 20rem;
      overflow-y: auto;
    }
  }
`;

type SelectFileModalProps = {
  matterId: string | undefined;
  viewingFolderId: string | "root" | undefined;
  aiWordSupport?: boolean;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
  onCreateMatterSpecificForm: (options: {
    filePath: string;
    fileName: string;
    fileId: string;
  }) => Promise<void>;
};

export const SelectFileModal = ({
  matterId,
  viewingFolderId,
  aiWordSupport,
  setViewingFolderId,
  onCreateMatterSpecificForm,
}: SelectFileModalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [selectedFileItem, setSelectedFileItem] = useState<FileItemType>();
  const [loadedFolders, setLoadedFolders] = useState<
    Record<string, FolderInfo>
  >({
    root: { id: "root", name: "Root", parentId: undefined },
  });
  const [uploadQuestionGenerationFiles] = useUploadQuestionGenerationFiles();

  const matterFiles = useLoadMatterFiles({
    matterId: matterId || "",
    folderId: viewingFolderId !== "root" ? viewingFolderId : undefined,
  });
  const close = () => {
    setSelectedFileItem(undefined);
    setViewingFolderId(undefined);
  };

  useEffect(() => {
    if (matterFiles.result) {
      const folders = matterFiles.result.filter(
        (fileItem) => fileItem.type === "folder",
      );
      setLoadedFolders((existing) => ({
        ...existing,
        ...fromEntries(
          folders.map((folder) => [
            folder.id,
            {
              id: folder.id,
              name: folder.name,
              parentId: folder.parentId || "root",
            },
          ]),
        ),
      }));
    }
  }, [matterFiles.result]);

  useEffect(() => setSelectedFileItem(undefined), [viewingFolderId]);

  const currentFolder = loadedFolders[viewingFolderId!];
  if (aiWordSupport) {
    availableFileExtensions.push(".doc", ".docx");
  }

  const onCreateForm = async () => {
    if (selectedFileItem?.type !== "file" || !selectedFileItem?.fileExtension)
      throw new Error(`Invalid file type or extension: ${selectedFileItem}`);

    setIsCreating(true);
    const result = await uploadQuestionGenerationFiles({
      variables: {
        fileNames: [
          `${selectedFileItem.name}${selectedFileItem.fileExtension}`,
        ],
        matterId,
        fileIds: [selectedFileItem.id],
      },
    });
    const fileInfo = result.data?.uploadQuestionGenerationFiles[0];

    if (!fileInfo)
      throw new Error("Failed to upload file for question generation");

    await onCreateMatterSpecificForm({
      filePath: fileInfo.key,
      fileName: selectedFileItem.name,
      fileId: selectedFileItem.id,
    });
    setIsCreating(false);
  };

  const isFileTooLarge =
    selectedFileItem?.type === "file" &&
    (selectedFileItem.sizeBytes || 0) > maxFileSizeMB * bytesPerMegaByte;

  return (
    <Modal
      header={{
        icon: { library: "lucide", name: "Zap", variant: "action" },
        text: "New Intake Form",
      }}
      open={!!viewingFolderId}
      onClose={close}
      footer={{
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: close,
          },
        ],
        right: [
          {
            text: "Create form",
            variant: "save",
            disabled: selectedFileItem?.type !== "file" || isFileTooLarge,
            onClick: onCreateForm,
          },
        ],
      }}
      closeOptions={{ clickOutside: false }}
      loading={matterFiles.loading || isCreating}
    >
      <SelectFileWrapper>
        <div>
          Upload existing document <RequiredAsterisk />
        </div>
        <div className="file-items">
          <FolderPathDisplay
            loadedFolders={loadedFolders}
            currentFolder={currentFolder}
            setViewingFolderId={setViewingFolderId}
          />
          <div className="file-item-list">
            {matterFiles.result
              ?.filter((f) =>
                f.type === "file"
                  ? f.fileExtension &&
                    availableFileExtensions.includes(
                      f.fileExtension.toLowerCase(),
                    )
                  : true,
              )
              .map((fileItem) => (
                <FileItem
                  key={fileItem.id}
                  fileItem={fileItem}
                  selectedFileItem={selectedFileItem}
                  setSelectedFileItem={setSelectedFileItem}
                  setViewingFolderId={setViewingFolderId}
                />
              ))}
          </div>
        </div>
      </SelectFileWrapper>
      {isFileTooLarge && (
        <FailureMessage
          action="select file"
          textOverride={`This file is too large to be processed. Files larger than ${maxFileSizeMB} MB are not currently supported.`}
        />
      )}
    </Modal>
  );
};
