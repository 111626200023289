import { Theme, css } from "@emotion/react";
import styled from "@emotion/styled";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { Icon } from "@smart/itops-components-dom";
import { UIKey } from "@smart/itops-ui-dom";

import { fieldColor, fieldIcon } from "../constants";
import { FieldTemplate } from "../types";

export const FieldComponentList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const fieldComponentSize = (props: { theme: Theme }) => css`
  width: ${props.theme.baseUnit * 12.5}rem;
  height: ${props.theme.baseUnit * 9}rem;
`;

const FieldWrapper = styled.div<{ fieldColor: UIKey; mapped?: boolean }>`
  ${fieldComponentSize};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.mapped
      ? props.theme.scheme[props.fieldColor].r20
      : props.theme.scheme.grey.r20};
  border-radius: 0.5rem;
  border: 3px solid transparent;
  cursor: grab;

  .component-icon {
    color: ${(props) => props.theme.scheme[props.fieldColor].r100};
  }

  .component-label {
    margin-top: ${(props) => props.theme.baseUnit}rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[aria-selected="true"] {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &[aria-selected="true"],
  &:not([aria-disabled="true"]):hover {
    border-color: ${(props) => props.theme.scheme[props.fieldColor].r100};
    color: ${(props) => props.theme.scheme[props.fieldColor].r100};
    background-color: ${(props) => props.theme.scheme.grey.r0};
  }
`;

type FieldComponentProps = FieldTemplate & {
  disabled?: boolean;
  isDragging?: boolean;
};

const FieldComponent = ({
  type,
  disabled,
  isDragging,
}: FieldComponentProps) => (
  <FieldWrapper
    aria-disabled={disabled}
    aria-selected={isDragging}
    fieldColor={fieldColor[type]}
    mapped={type === "mapped"}
  >
    <Icon className="component-icon" {...fieldIcon[type]} />
    <span className="component-label">{fieldFallbackLabel[type]}</span>
  </FieldWrapper>
);

export { fieldComponentSize, FieldComponent, FieldComponentProps };
