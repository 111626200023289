import styled from "@emotion/styled";
import { Droppable } from "@hello-pangea/dnd";

import { Icon } from "@smart/itops-components-dom";

import { fieldComponentSize } from "../navigation/field-component";

const Container = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.breakPoints.readable}px;
  width: 100%;
  position: relative;

  .dropper {
    ${fieldComponentSize};
    height: 6rem;
    margin: 0 auto;
  }

  .placeholder {
    display: none;
  }

  .indicator {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    pointer-events: none;
    color: ${(props) => props.theme.scheme.grey.r30};
    opacity: 1;
    transition:
      opacity 0.2s ease-in-out,
      color 0.1s ease-in-out;

    &[aria-selected="true"] {
      color: ${(props) => props.theme.scheme.blue.r100};
    }

    &[aria-hidden="true"] {
      opacity: 0;
    }

    &::before,
    &::after {
      content: "";
      flex: 1;
      opacity: 1;
      height: 0.3rem;
      max-width: 10rem;
      border-radius: 0.5rem;
      background: currentColor;
    }

    .indicator-icon {
      width: 2.2rem;
      height: 2.2rem;
      background: var(--background);
      border: 3px solid currentColor;
      border-radius: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

type SeparatorProps = {
  index: number;
  isFieldReordering?: boolean;
  isShrinking?: boolean;
  groupUri?: string | null;
};

const Separator = ({
  index,
  isFieldReordering,
  isShrinking,
  groupUri,
}: SeparatorProps) => (
  <Droppable
    droppableId={
      groupUri ? `${groupUri}|${index}` : `droppable-field-separator|${index}`
    }
  >
    {(provider, snapshot) => (
      <Container>
        <div
          className="indicator"
          aria-selected={snapshot.isDraggingOver}
          aria-hidden={isFieldReordering || isShrinking}
        >
          <div className="indicator-icon">
            <Icon library="lucide" name="Plus" size={18} stroke={4} />
          </div>
        </div>
        <div className="dropper" ref={provider.innerRef} />
        <div className="placeholder">{provider.placeholder}</div>
      </Container>
    )}
  </Droppable>
);

export { Separator };
