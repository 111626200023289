import { MouseEvent } from "react";
import { twMerge } from "tailwind-merge";

import {
  partBase,
  partActive,
  partHover,
  partFocus,
  partDisabled,
  partForceActive,
} from "./class-names";
import { Icon, IconName } from "../icon";

export type DropdownPartProps = {
  leftIcon?: IconName;
  rightIcon?: IconName;
  label: string;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: () => void;
  disabled?: boolean;
  active?: boolean;
  hidden?: boolean;
};

export const DropdownPart = ({
  leftIcon,
  rightIcon,
  label,
  onClick,
  onMouseEnter,
  disabled,
  active,
  hidden,
}: DropdownPartProps) =>
  hidden ? null : (
    <button
      className={twMerge(
        partBase,
        partActive,
        partHover,
        partFocus,
        disabled && partDisabled,
        active && partForceActive,
      )}
      onMouseEnter={onMouseEnter}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
      onMouseUp={(e) => (e.target as HTMLButtonElement).blur()}
      onTouchEnd={(e) => (e.target as HTMLButtonElement).blur()}
      type="button"
      aria-label={label}
    >
      <div className="flex gap-small items-center">
        {leftIcon && <Icon name={leftIcon} className="w-[1.6rem] h-[1.6rem]" />}
        <p className="text-ui">{label}</p>
      </div>
      {rightIcon && <Icon name={rightIcon} />}
    </button>
  );
