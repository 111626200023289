import { clsx } from "clsx";

import { alertBannerSectionPalettes } from "./class-names";
import { AlertProps } from "./types";
import { Button } from "../button";
import { Icon } from "../icon";
import { Link as LinkComponent } from "../link";

export const AlertSection = ({
  palette = "default",
  heading,
  content,
  elaboration,
  action,
  onClose,
}: AlertProps) => (
  <div
    className={clsx(
      alertBannerSectionPalettes[palette].background,
      "px-[1.6rem] py-[1rem] rounded relative overflow-hidden",
    )}
  >
    <div
      className={clsx(
        alertBannerSectionPalettes[palette].borderColor,
        "w-full h-[0.4rem] absolute top-0 left-0 readable:w-[0.4rem] readable:h-full",
      )}
    />
    <div className="flex gap-[0.8rem] items-center">
      <span className="w-[2.4rem] h-[2.4rem] inline-flex justify-center items-center">
        <Icon
          className={alertBannerSectionPalettes[palette].iconColor}
          name={alertBannerSectionPalettes[palette].iconName}
        />
      </span>
      <h2
        className={clsx(
          alertBannerSectionPalettes[palette].iconColor,
          "flex-1 text-paragraph font-semibold",
        )}
      >
        {heading}
      </h2>
      <Button
        variant="secondarySubtle"
        leftIcon="solidXmarkLarge"
        onClick={onClose}
      />
    </div>
    <div className="pl-[3.2rem] pt-[0.8rem]">
      <div className="text-ui">{content}</div>
      <div className="flex gap-[1.6rem] mt-[1.6rem] mb-[1rem]">
        {elaboration && (
          <LinkComponent
            variant="default"
            text={elaboration.text}
            onClick={elaboration.onClick}
          />
        )}
        {action && (
          <LinkComponent
            variant="subtle"
            text={action.text}
            onClick={action.onClick}
          />
        )}
      </div>
    </div>
  </div>
);
