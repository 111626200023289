import styled from "@emotion/styled";
import { Editable, Slate } from "slate-react";

import { isTestEnv } from "@smart/itops-utils-basic";

import { Block, Leaf, TestEditable } from "./elements";
import {
  useEditorState,
  EditorContextProvider,
  EditorPlaceholders,
} from "./hooks";
import { Toolbar } from "./toolbar";

const EditorWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.scheme.grey.r35};
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.scheme.grey.r0};
  overflow: hidden;
  width: 100%;

  .editable {
    min-height: 25rem;
    width: 100%;
    padding: 1rem;
    outline: none;
  }
`;

export type EditorProps<P extends string> = {
  initialTextValue?: string;
  placeholders?: EditorPlaceholders<P>;
  onValueChange?: (v: string) => void;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
};

export const Editor = <P extends string>({
  initialTextValue,
  placeholders,
  onValueChange,
  onBlur,
  className,
  disabled,
  autoFocus,
  dataTestId,
}: EditorProps<P>) => {
  const { editor, initialValue, onChange, onKeyDown, modal, setModal } =
    useEditorState({
      initialTextValue,
      onValueChange,
      autoFocus,
    });

  return (
    <EditorWrapper
      className={className}
      data-testid={dataTestId}
      onBlur={onBlur}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        onValueChange={onChange}
      >
        <EditorContextProvider
          value={{
            modal,
            setModal,
            placeholders,
          }}
        >
          <Toolbar />
          <Editable
            className="editable"
            data-testid={dataTestId && `${dataTestId}-editable`}
            readOnly={disabled}
            renderElement={Block}
            renderLeaf={Leaf}
            onKeyDown={onKeyDown}
            placeholder=""
            spellCheck
          />
          {!!isTestEnv() && (
            <TestEditable
              dataTestId={dataTestId && `${dataTestId}-textarea`}
              disabled={disabled}
              onKeyDown={onKeyDown}
            />
          )}
        </EditorContextProvider>
      </Slate>
    </EditorWrapper>
  );
};
