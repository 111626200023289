import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { archie } from "@smart/bridge-images-dom";
import { defaultAIFillSettings, SectionNS } from "@smart/bridge-types-basic";
import { DragNDropList } from "@smart/itops-components-dom";
import {
  Button,
  EditableText,
  FullButtonRow,
  Modal,
  Toggle,
} from "@smart/itops-ui-dom";
import { rankBetween, sortField } from "@smart/itops-utils-basic";

import { MovableSection } from "./movable-section";
import {
  EditableSection,
  GqlForm,
  UpdateAIFillSettings,
  UpdatedSection,
} from "../../types";

const SectionList = styled.div`
  .draggable-sections {
    display: flex;
    flex-direction: column;
    margin: 0.4rem 0 0.4rem;
  }
`;

const AutoFillSection = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.scheme.grey.r15};
  border-radius: 0.5rem;
  gap: 1rem;
  margin: 0.4rem 0;
  padding: 0.8rem;

  .autofill-section-content {
    display: flex;
    margin-left: 0.5rem;

    .archie-icon {
      width: 2.4rem;
    }
  }
`;

type EditSectionsModalProps = {
  editSectionsModal: boolean;
  onClose: () => void;
  sections: EditableSection[];
  updateAllSections: (sections: UpdatedSection[]) => Promise<void>;
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

const EditSectionsModal = ({
  editSectionsModal,
  onClose,
  sections,
  updateAllSections,
  aiFillSettings,
  updateAIFillSettings,
}: EditSectionsModalProps) => {
  const [loading, setLoading] = useState(false);
  const [updatedSections, setUpdatedSections] = useState<UpdatedSection[]>([]);
  const [newSectionUri, setNewSectionUri] = useState<string>();
  const defaultTitle =
    aiFillSettings?.sectionTitle || defaultAIFillSettings.sectionTitle;
  const [editingTitle, setEditingTitle] = useState(defaultTitle);
  const [autofillToggle, setAutofillToggle] = useState(
    !!aiFillSettings?.allowAiFill,
  );

  useEffect(() => {
    setEditingTitle(defaultTitle);
    setAutofillToggle(!!aiFillSettings?.allowAiFill);
    if (editSectionsModal) {
      setNewSectionUri(undefined);
      setUpdatedSections(
        sortField(
          sections.map((s) => ({
            uri: s.uri,
            title: s.values?.title || "",
            order: s.values?.order || rankBetween({}),
            description: s.values?.description,
            links: s.values?.links,
            updated: !s.values,
            created: !s.values,
          })),
          { key: "order", dir: "asc" },
        ),
      );
    }
  }, [editSectionsModal]);

  const moveSection = (from: number, to: number) => {
    const afterIndex = from > to ? to - 1 : to;
    const newOrder = rankBetween({
      after: updatedSections[afterIndex]?.order,
      before: updatedSections[afterIndex + 1]?.order,
    });

    const newSections = [...updatedSections];
    newSections[from] = {
      ...newSections[from],
      order: newOrder,
      updated: true,
    };
    setUpdatedSections(sortField(newSections, { key: "order", dir: "asc" }));
  };

  return (
    <Modal
      loading={loading}
      header={{
        text: "Edit sections",
      }}
      footer={{
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: onClose,
          },
        ],
        right: [
          {
            text: "Save sections",
            variant: "save",
            onClick: async () => {
              setLoading(true);
              await updateAllSections(updatedSections);
              if (aiFillSettings?.allowAiFill) {
                await updateAIFillSettings({
                  sectionTitle: editingTitle,
                  allowAiFill: autofillToggle,
                });
              }
              setLoading(false);
              onClose();
            },
          },
        ],
      }}
      open={editSectionsModal}
      onClose={() => {
        if (!loading) onClose();
      }}
    >
      <SectionList>
        {aiFillSettings?.allowAiFill && (
          <AutoFillSection>
            <div className="autofill-section-content">
              <img className="archie-icon" src={archie} alt="Archie" />
              <EditableText
                text={editingTitle}
                onChange={setEditingTitle}
                onComplete={async () => {}}
                dataTestId="autofill-title"
                fontSize="subHeading"
                bold
              />
            </div>
            <Toggle
              size={3.5}
              toggled={autofillToggle}
              loading={loading}
              onClick={() => setAutofillToggle(!autofillToggle)}
              dataTestId="ai-fill-toggle-sections-modal"
            />
          </AutoFillSection>
        )}
        <DragNDropList
          id="draggable-sections"
          className="draggable-sections"
          items={updatedSections}
          itemKey="uri"
          render={MovableSection}
          renderProps={{
            numOfSections: updatedSections.length,
            moveUp: (index: number) => moveSection(index, index - 1),
            moveDown: (index: number) => moveSection(index, index + 1),
            setTitle: (index: number, title: string) => {
              const newSections = [...updatedSections];
              newSections[index] = {
                ...newSections[index],
                title,
                updated: true,
              };
              setUpdatedSections(newSections);
            },
            setDeleted: (index: number, deleted: boolean) => {
              const newSections = [...updatedSections];
              newSections[index].deleted = deleted;
              setUpdatedSections(newSections);
            },
            newSectionUri,
          }}
          onMove={({ source, destination }) =>
            moveSection(source.index, destination.index)
          }
        />
        <FullButtonRow>
          <Button
            text="Add section"
            icon={{ library: "lucide", name: "Plus" }}
            variant="action"
            onClick={() => {
              const createdSectionUri = SectionNS.generateUri();
              setNewSectionUri(createdSectionUri);
              setUpdatedSections([
                ...updatedSections,
                {
                  uri: createdSectionUri,
                  title: "",
                  order: rankBetween({
                    after: updatedSections[updatedSections.length - 1].order,
                  }),
                  updated: true,
                  created: true,
                },
              ]);
            }}
          />
        </FullButtonRow>
      </SectionList>
    </Modal>
  );
};

export { EditSectionsModal };
