import styled from "@emotion/styled";
import { Fragment } from "react";

import { MultipleTextLabel } from "./multiple-text-label";

const MultpileLabelWrapper = styled.span`
  display: flex;
  flex-flow: row wrap;
  gap: 0.8rem;

  .spacer {
    display: block;
    border-right: 1px solid ${(props) => props.theme.scheme.grey.r30};
    margin: -0.2rem 0;
  }
`;

export type MultipleLabelProps = {
  labels: string[][];
  placeholders?: string[];
  className?: string;
  loading?: boolean;
  maxVisibleItems?: number;
};

export const MultipleLabel = ({
  labels,
  placeholders,
  className,
  loading,
  maxVisibleItems,
}: MultipleLabelProps) => (
  <MultpileLabelWrapper className={className}>
    {labels.map((label, index) =>
      label.length > 0 ? (
        <Fragment key={placeholders?.[index] || index}>
          {index > 0 && <span className="spacer" />}
          <MultipleTextLabel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            texts={label}
            placeholder={placeholders?.[index]}
            loading={loading}
            maxVisibleItems={maxVisibleItems}
          />
        </Fragment>
      ) : null,
    )}
  </MultpileLabelWrapper>
);
