import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useSlate } from "slate-react";

import { Popup } from "./popups";
import { Button } from "../../button";
import { Tooltip } from "../../tooltip";
import {
  hotkeyLabel,
  isBlockActive,
  markOptions,
  marks,
  modalOptions,
  modals,
  toggleMark,
  useEditorContext,
} from "../hooks";

const toolbarWrapperStyles =
  "p-4 flex flex-row flex-wrap gap-3 border-b border-neutral-100 mx-4";
const popupStyles = "bg-white rounded shadow-dropdownMenu z-1";

export const Toolbar = () => {
  const editor = useSlate();
  const { placeholders, modal, setModal } = useEditorContext();
  const visibleModalOptions = {
    link: true,
    placeholder: !!placeholders?.keys.length,
  };

  const popup = useFloating({
    placement: "bottom-start",
    open: !!modal,
    onOpenChange: () => setModal(undefined),
    middleware: [
      shift({ padding: 10 }),
      flip({ fallbackPlacements: ["bottom", "top"] }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const popupInteractions = useInteractions([useDismiss(popup.context)]);

  return (
    <div className={toolbarWrapperStyles}>
      {markOptions.map((option) => (
        <Tooltip
          id={option}
          key={option}
          placement="top"
          text={hotkeyLabel(marks[option].hotkey)}
        >
          <Button
            variant="inlineSubtle"
            leftIcon={marks[option].icon}
            onMouseDown={toggleMark(editor, option)}
            onTouchStart={toggleMark(editor, option)}
          />
        </Tooltip>
      ))}
      {modalOptions.map((option) =>
        visibleModalOptions[option] ? (
          <Tooltip
            id={option}
            key={option}
            placement="top"
            text={
              modal?.option === option ||
              isBlockActive(editor, ["link", "placeholder"])
                ? undefined
                : hotkeyLabel(modals[option].hotkey)
            }
          >
            <div
              ref={
                modal?.option === option ? popup.refs.setReference : undefined
              }
              {...popupInteractions.getReferenceProps()}
            >
              <Button
                variant="inlineSubtle"
                leftIcon={modals[option].icon}
                disabled={isBlockActive(editor, ["link", "placeholder"])}
                onMouseDown={() => setModal({ option })}
                onTouchStart={() => setModal({ option })}
              />
            </div>
          </Tooltip>
        ) : null,
      )}
      {!!modal && (
        <span
          className={popupStyles}
          ref={popup.refs.setFloating}
          style={popup.floatingStyles}
          {...popupInteractions.getFloatingProps()}
        >
          <Popup {...modal} />
        </span>
      )}
    </div>
  );
};
