import styled from "@emotion/styled";

import { Icon, IconDetail } from "@smart/itops-icons-dom";
import { Variant, variantStyle } from "@smart/itops-ui-dom";

const ItemWrapper = styled.button<{ underline?: boolean; variant?: Variant }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.6rem;

  background: none;
  border: 0;
  cursor: pointer;
  padding: 0.6rem 1rem;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .picker-item-checkbox {
    margin: 0 0.6rem;
    height: 1.6rem;
    width: 1.6rem;

    background-color: ${(props) => props.theme.scheme.grey.r10};
    border: 1px solid ${(props) => props.theme.scheme.grey.r35};
    border-radius: 0.4rem;
    color: ${(props) => props.theme.scheme.grey.r10};

    display: flex;
    align-items: center;
    justify-content: center;

    &[aria-checked="true"] {
      background-color: ${(props) => props.theme.scheme.blue.r100};
      border-color: ${(props) => props.theme.scheme.blue.r100};
      color: ${(props) => props.theme.scheme.grey.r0};
    }
    &[aria-disabled="true"] {
      background-color: ${(props) => props.theme.scheme.grey.r35};
      border-color: ${(props) => props.theme.scheme.grey.r35};
      color: ${(props) => props.theme.scheme.grey.r0};
    }
  }

  .picker-item-icon {
    ${variantStyle}
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
  }

  .picker-item-text {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 350;
    margin: 0;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  }
`;

export type PickerItemProps = {
  icon?: IconDetail & { variant?: Variant };
  text: string;
  onClick: () => void;
  checked?: boolean;
  disabled?: boolean;
};

export const PickerItem = ({
  icon,
  text,
  onClick,
  checked,
  disabled,
}: PickerItemProps) => (
  <ItemWrapper
    variant={icon?.variant}
    disabled={disabled}
    underline={checked === undefined}
    onClick={onClick}
  >
    {checked !== undefined && (
      <div
        className="picker-item-checkbox"
        role="checkbox"
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <Icon size={12} stroke={3} library="lucide" name="Check" />
      </div>
    )}
    {icon && (
      <div className="picker-item-icon">
        <Icon size={16} {...icon} />
      </div>
    )}
    <p className="picker-item-text">{text}</p>
  </ItemWrapper>
);
