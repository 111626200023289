import styled from "@emotion/styled";
import { Droppable } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";

import { archie } from "@smart/bridge-images-dom";
import {
  aiFillSectionUri,
  defaultAIFillSettings,
} from "@smart/bridge-types-basic";
import { Button } from "@smart/itops-ui-dom";

import { EditSectionsModal } from "./edit-sections-modal";
import { NavItem, NavItems } from "./nav-item";
import { NavigationWrapper } from "./wrapper";
import { sectionNavigationDroppableId } from "../constants";
import {
  EditableSection,
  GqlForm,
  UpdateAIFillSettings,
  UpdatedSection,
} from "../types";

const AIFillNavItem = styled.div`
  margin-bottom: 1rem;
`;

type SectionNavigationProps = {
  sections: EditableSection[];
  currentSectionUri: string;
  disabled?: boolean;
  loading: boolean;
  setCurrentSectionUri: (sectionUri: string) => void;
  updateAllSections: (sections: UpdatedSection[]) => Promise<void>;
  setDroppedOnSectionUri: (sectionUri: string | undefined) => void;
  isDraggingItem: boolean;
  setIsValidSection: (isValid: boolean) => void;
  isValidSection: boolean;
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

const SectionNavigation = ({
  sections,
  currentSectionUri,
  disabled,
  loading,
  setCurrentSectionUri,
  updateAllSections,
  isDraggingItem,
  setDroppedOnSectionUri,
  setIsValidSection,
  isValidSection,
  aiFillSettings,
  updateAIFillSettings,
}: SectionNavigationProps) => {
  const [sectionsModal, showSectionsModal] = useState(false);

  useEffect(() => {
    if (
      !aiFillSettings?.allowAiFill &&
      currentSectionUri === aiFillSectionUri
    ) {
      setCurrentSectionUri(sections[0].uri);
    }
  }, [!!aiFillSettings?.allowAiFill]);

  return (
    <NavigationWrapper data-testid="sections-navigation">
      <h2>Sections</h2>
      {aiFillSettings?.allowAiFill && (
        <AIFillNavItem>
          <NavItem
            key={aiFillSectionUri}
            section={{
              uri: aiFillSectionUri,
              values: {
                title:
                  aiFillSettings.sectionTitle ||
                  defaultAIFillSettings.sectionTitle,
              },
            }}
            index={
              <img src={archie} alt="Archie" style={{ width: "2.4rem" }} />
            }
            loading={loading}
            currentSectionUri={currentSectionUri}
            setCurrentSectionUri={setCurrentSectionUri}
            isDraggingItem={false}
            setDroppedOnSectionUri={setDroppedOnSectionUri}
            setIsValidSection={setIsValidSection}
          />
        </AIFillNavItem>
      )}
      <Droppable
        droppableId={sectionNavigationDroppableId}
        type="GROUP"
        isDropDisabled={!isValidSection}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <NavItems>
              {sections.map((section, index) => (
                <NavItem
                  key={section.uri}
                  section={section}
                  index={index}
                  loading={loading}
                  currentSectionUri={currentSectionUri}
                  setCurrentSectionUri={setCurrentSectionUri}
                  isDraggingItem={isDraggingItem}
                  setDroppedOnSectionUri={setDroppedOnSectionUri}
                  setIsValidSection={setIsValidSection}
                />
              ))}
            </NavItems>
            <span style={{ display: "none" }}>{provided.placeholder}</span>
          </div>
        )}
      </Droppable>
      <Button
        text="Edit sections"
        variant="action"
        disabled={loading || disabled}
        onClick={() => showSectionsModal(true)}
      />
      <EditSectionsModal
        editSectionsModal={sectionsModal}
        onClose={() => showSectionsModal(false)}
        sections={sections}
        updateAllSections={updateAllSections}
        aiFillSettings={aiFillSettings}
        updateAIFillSettings={updateAIFillSettings}
      />
    </NavigationWrapper>
  );
};

export { SectionNavigation, SectionNavigationProps };
