import { useEffect, useState } from "react";

import { Modal } from "@smart/itops-ui-dom";
import { isNotNullOrUndefined, toSentenceCase } from "@smart/itops-utils-basic";

import { isRoleProviderField, mapMatterField } from "./helpers";
import { useMappedMatterFields } from "./hooks";
import { Picker, PickerSelection } from "./picker";
import {
  GqlField,
  GqlGroup,
  GqlMatterLayout,
  GqlMatterType,
} from "../../../types";
import { fieldIcon } from "../../constants";
import {
  CreateMappedFields,
  LoadMatterFields,
  GroupOptions,
} from "../../types";

export type MappedFieldsOptions = {
  beforePosition?: string;
  afterPosition?: string;
  destinationGroupUri?: string;
  fixedSelection?: PickerSelection;
};

export type MappedFieldsModalProps = {
  onClose: () => void;
  createFields: CreateMappedFields;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  groups: GqlGroup[];
  fields: GqlField[];
  mappedFieldsOptions: MappedFieldsOptions | undefined;
  loading?: boolean;
};

export const MappedFieldsModal = ({
  mappedFieldsOptions,
  onClose,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  createFields,
  fields,
  groups,
  loading: dataLoading,
}: MappedFieldsModalProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const initial: PickerSelection = {
    layout: undefined,
    prefix: [],
    fields: [],
    components: [],
  };

  const [selection, setSelection] = useState<PickerSelection>(initial);

  useEffect(() => {
    if (!mappedFieldsOptions) {
      setSelection(initial);
      setIsSaving(false);
    } else if (mappedFieldsOptions.fixedSelection) {
      setSelection(mappedFieldsOptions.fixedSelection);
    }
  }, [mappedFieldsOptions]);

  const {
    isLoading: isLoadingMatterFields,
    currentLayoutComponent,
    matterFields,
    existingMappedFields,
  } = useMappedMatterFields({
    selection,
    fields,
    matterTypes,
    groups,
    loadMatterFields,
  });

  const onSaveChanges = async () => {
    setIsSaving(true);

    try {
      if (selection.fields.length && selection.layout) {
        const group: GroupOptions | undefined =
          currentLayoutComponent?.type === "role" &&
          selection.fields.filter(isRoleProviderField).length > 0
            ? {
                uri: mappedFieldsOptions?.destinationGroupUri,
                type: "layoutContact",
                label: toSentenceCase(currentLayoutComponent.displayName),
                layout: {
                  id: selection.layout.id,
                  name: selection.layout.name,
                  providerId: selection.layout.providerId,
                  parentId: selection.layout.parentId || undefined,
                  parentName: selection.layout.parentName || undefined,
                  parentProviderId:
                    selection.layout.parentProviderId || undefined,
                },
                field: {
                  name: currentLayoutComponent.name,
                  possibleValues: [],
                  type: "Role",
                },
              }
            : undefined;

        await createFields({
          beforePosition: mappedFieldsOptions?.beforePosition,
          afterPosition: mappedFieldsOptions?.afterPosition,
          destinationGroupUri: mappedFieldsOptions?.destinationGroupUri,
          newFields: selection.fields
            .map(mapMatterField(selection.layout))
            .filter(isNotNullOrUndefined),
          group,
        });
      }
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Modal
      open={!!mappedFieldsOptions}
      onClose={onClose}
      loading={isSaving}
      header={{
        icon: { ...fieldIcon.mapped, variant: "success" },
        text: "Select mapped fields",
      }}
      footer={{
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: onClose,
          },
        ],
        right: [
          {
            key: "addMappedFields",
            text: `Add ${selection.fields.length || ""} mapped field${selection.fields.length !== 1 ? "s" : ""}`,
            variant: "save",
            disabled:
              !selection.layout ||
              !selection.fields.length ||
              isLoadingMatterFields ||
              isSaving ||
              dataLoading,
            onClick: onSaveChanges,
          },
        ],
      }}
      size={{ width: "tablet", height: "medium" }}
    >
      <Picker
        loading={dataLoading || isLoadingMatterFields || false}
        isLoadingMatterFields={isLoadingMatterFields}
        fixedSelection={mappedFieldsOptions?.fixedSelection}
        selection={selection}
        setSelection={setSelection}
        matterTypes={matterTypes || []}
        layouts={matterLayouts || []}
        fields={matterFields || []}
        existingMappedFields={existingMappedFields}
        isInGroup={!!mappedFieldsOptions?.destinationGroupUri}
      />
    </Modal>
  );
};
