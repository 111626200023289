import { useNavigate } from "react-router-dom";

import { TemplateResponseData } from "@smart/bridge-templates-basic";
import { Icon } from "@smart/itops-icons-dom";
import { Button, Heading, Tabs } from "@smart/itops-ui-dom";

import { Actions } from "./actions";
import { FormInfo } from "./form-info";
import { EditingFormInfo } from "../../list-forms/form";
import { NavRight } from "../../shared/nav-right";
import {
  GqlField,
  GqlFormCategory,
  GqlGroup,
  GqlMatterType,
  GqlSetting,
} from "../../types";

type FormHeadingProps = {
  formUri: string;
  formTitle: string;
  tab?: "questions" | "preview";
  isMatterSpecific: boolean;
  isFormActive: boolean | undefined;
  canActivate?: boolean;
  canUpdate?: boolean;
  disabled?: boolean;
  loading: {
    form: boolean;
    matterTypes: boolean;
  };
  updating?: boolean;
  setTab: React.Dispatch<React.SetStateAction<"questions" | "preview">>;
  onActiveToggleClick: () => Promise<void>;
  matterTypeNames: string[];
  matterTypeLocations: string[];
  onFormTitleUpdate: (title: string) => Promise<void>;
  validateFormTitle: (title: string) => boolean;
  confirmationMessage: string;
  saveConfirmationMessage: (value?: string) => Promise<void>;
  defaultConfirmationMessage?: string | null;
  placeholderData: Partial<TemplateResponseData>;
  updateFormInfo: (values: EditingFormInfo) => Promise<void>;
  setting?: GqlSetting | null;
  category?: GqlFormCategory;
  matterTypes: GqlMatterType[];
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  previewErrorToggle: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clearIntakeForm: () => void;
  disableTabs?: boolean;
};

const FormHeading = ({
  formUri,
  formTitle,
  tab,
  isMatterSpecific,
  isFormActive,
  canActivate,
  canUpdate,
  disabled,
  loading,
  updating,
  setTab,
  onActiveToggleClick,
  matterTypeNames,
  matterTypeLocations,
  onFormTitleUpdate,
  validateFormTitle,
  confirmationMessage,
  saveConfirmationMessage,
  defaultConfirmationMessage,
  placeholderData,
  updateFormInfo,
  setting,
  category,
  matterTypes,
  groups,
  fields,
  previewErrorToggle,
  clearIntakeForm,
  disableTabs,
}: FormHeadingProps) => {
  const navigate = useNavigate();

  return (
    <Heading
      nav={
        <>
          <Button
            left={<Icon library="lucide" name="MoveLeft" />}
            size="base"
            text={isMatterSpecific ? "Back" : "Back to all forms"}
            variant="navigation"
            kind="borderless"
            onClick={() =>
              navigate(`/embedded/${isMatterSpecific ? "tab" : "builder"}`)
            }
          />
          <NavRight />
        </>
      }
      left={
        <FormInfo
          formTitle={formTitle}
          matterTypeNames={matterTypeNames}
          matterTypeLocations={matterTypeLocations}
          disabled={disabled}
          isMatterSpecific={isMatterSpecific}
          loading={loading}
          onFormTitleUpdate={onFormTitleUpdate}
          validateFormTitle={validateFormTitle}
          updateFormInfo={updateFormInfo}
          setting={setting}
          category={category}
          matterTypes={matterTypes}
          groups={groups}
          fields={fields}
        />
      }
      center={
        <Tabs
          tabs={["questions", "preview"]}
          labels={{ questions: "Edit form", preview: "Preview form" }}
          tab={tab}
          selectTab={setTab}
          disabled={disableTabs}
        />
      }
      right={
        <Actions
          formUri={formUri}
          isMatterSpecific={isMatterSpecific}
          isFormActive={isFormActive}
          canActivate={canActivate}
          disabled={disabled}
          loading={loading.form && !formTitle}
          canUpdate={canUpdate}
          onActiveToggleClick={onActiveToggleClick}
          confirmationMessage={confirmationMessage}
          saveConfirmationMessage={saveConfirmationMessage}
          defaultConfirmationMessage={defaultConfirmationMessage}
          placeholderData={placeholderData}
          previewErrorToggle={previewErrorToggle}
          isPreview={tab === "preview"}
          clearIntakeForm={clearIntakeForm}
        />
      }
      loading={updating}
    />
  );
};

export { FormHeading, FormHeadingProps };
