import styled from "@emotion/styled";
import { ReactNode } from "react";

import { archie2 } from "@smart/bridge-images-dom";
import { Icon } from "@smart/itops-icons-dom";
import { smokeballTheme } from "@smart/itops-smokeball-components-dom";
import { Toggle, Tooltip } from "@smart/itops-ui-dom";

const AllowAIFillToggleWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  z-index: 2;

  .toggle-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: ${(props) => props.theme.fontSize.base};

    h4 {
      margin: 1rem 0;
    }

    .archie-icon {
      width: 2.4rem;
    }

    .tooltip {
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
    }
  }
`;

type AllowAIFillToggleProps = {
  toggled: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
  right?: ReactNode;
  label: string;
  tooltip: string;
  disabled?: boolean;
};

export const AllowAIFillToggle = ({
  toggled,
  loading,
  onClick,
  right,
  label,
  tooltip,
  disabled,
}: AllowAIFillToggleProps) => (
  <AllowAIFillToggleWrapper disabled={disabled}>
    <div className="toggle-info">
      <img className="archie-icon" src={archie2} alt="Archie" />
      <span>{label}</span>
      <Tooltip
        tooltipText={tooltip}
        placement="bottom"
        offsetBy={7}
        className="tooltip"
      >
        <Icon
          library="lucide"
          name="AlertCircle"
          size={16}
          color={smokeballTheme.scheme.blue.r100}
        />
      </Tooltip>
      {right}
    </div>
    <Toggle
      size={3.5}
      toggled={toggled}
      loading={loading}
      onClick={onClick}
      dataTestId="ai-fill-toggle"
      disabled={disabled}
    />
  </AllowAIFillToggleWrapper>
);
