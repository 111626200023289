import styled from "@emotion/styled";

const FolderPathDisplayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.scheme.grey.r15};
  padding: 1rem 0.5rem;

  .folder-path-part {
    display: flex;
    align-items: center;

    &::after {
      color: ${(props) => props.theme.scheme.grey.r50};
      font-size: 1.5em;
      font-weight: 100;
      content: "/";
    }
  }
`;

const PathPart = styled.button<{ isCurrent: boolean }>`
  cursor: pointer;
  background: none;
  border: none;

  color: ${(props) => !props.isCurrent && props.theme.scheme.blue.r100};
  text-decoration: ${(props) => (props.isCurrent ? "none" : "underline")};
`;

export type FolderInfo = {
  id: string;
  name: string;
  parentId: string | undefined | null;
};

type FolderPathDisplayProps = {
  currentFolder: FolderInfo;
  loadedFolders: Record<string, FolderInfo>;
  setViewingFolderId: (viewing: string | "root" | undefined) => void;
};

export const FolderPathDisplay = ({
  currentFolder,
  loadedFolders,
  setViewingFolderId,
}: FolderPathDisplayProps) => {
  const getFolders = () => {
    const folders: FolderInfo[] = [];

    folders.unshift(currentFolder);

    let { parentId } = currentFolder;
    while (parentId) {
      const parentFolder = loadedFolders[parentId];
      folders.unshift(parentFolder);
      parentId = parentFolder.parentId;
    }

    return folders;
  };

  return (
    <FolderPathDisplayWrapper>
      {getFolders().map((folder) => (
        <div key={folder.id} className="folder-path-part">
          <PathPart
            type="button"
            isCurrent={currentFolder.id === folder.id}
            onClick={() => setViewingFolderId(folder.id)}
          >
            {folder.id === "root" ? "Documents" : folder.name}
          </PathPart>
        </div>
      ))}
    </FolderPathDisplayWrapper>
  );
};
