import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import {
  FailureMessage,
  FieldList,
  Modal,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { buildLogProps } from "@smart/itops-utils-basic";
import { RoleContact } from "@smart/manage-hooks-dom";

export type TabSetEmailProps = {
  onClose: () => void;
  onSetEmail: (props: {
    roleContact: RoleContact;
    email: string;
  }) => Promise<void>;
  selected: RoleContact | undefined;
};

const tabSetEmailSchema = z.object({
  email: z
    .string()
    .min(1, "Please enter an email")
    .email("Please enter a valid email"),
});

export const TabSetEmail = ({
  onClose,
  onSetEmail,
  selected,
}: TabSetEmailProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(tabSetEmailSchema),
  });
  const { handleSubmit, control, reset, getValues, setValue } = form;

  const onSubmit = handleSubmit(async ({ email }) => {
    setHasError(undefined);
    setIsLoading(true);
    if (selected) {
      try {
        await onSetEmail({ roleContact: selected, email });
        onClose();
      } catch (e) {
        const { logMessage, errorProps } = buildLogProps(e);
        setHasError(`${logMessage} - ${errorProps.message}`);
      }
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (selected?.email && !getValues("email")) {
      setValue("email", selected.email);
    }
  }, [selected?.email]);

  const close = () => {
    reset();
    onClose();
  };
  return (
    <form onSubmit={onSubmit}>
      <Modal
        header={{
          icon: "solidSave",
          iconBackgroundColor: "cyan",
          text: `${selected?.email ? "Update email for" : "Add email to"} contact`,
        }}
        open={!!selected}
        onClose={close}
        footer={{
          buttons: [
            {
              text: "Cancel",
              type: "reset",
              variant: "secondarySubtle",
              onClick: close,
            },
            {
              text: `${selected?.email ? "Update" : "Add"} email`,
              type: "submit",
            },
          ],
        }}
        closeOptions={{ clickOutside: false }}
        loading={isLoading}
        dataTestId="set-email-modal"
      >
        <FormProvider {...form}>
          <div className="additional-info">
            <p>
              Add a valid email address to share the form. This email will also
              be saved to the contact.
            </p>
          </div>
          <FieldList>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextFieldInput
                  id={field.name}
                  message={fieldState.error?.message}
                  error={!!fieldState.error}
                  dataTestId="set-email"
                  {...field}
                />
              )}
            />
            {hasError && (
              <FailureMessage
                action="save contact information"
                title={hasError}
              />
            )}
          </FieldList>
        </FormProvider>
      </Modal>
    </form>
  );
};
