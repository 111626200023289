import { useState } from "react";

import {
  defaultTemplate,
  templateResponsePlaceholders,
  templatePlaceholderDetails,
  TemplateResponseData,
  appendDownloadFormLink,
} from "@smart/bridge-templates-basic";
import { Editor, Modal } from "@smart/itops-ui-dom";

type SettingsProps = {
  onSave: (response: string | undefined) => Promise<void>;
  onClose: () => void;
  settingsModal: boolean;
  currentConfirmationMessage: string;
  defaultConfirmationMessage?: string | null;
  placeholderData: Partial<TemplateResponseData>;
};

export const Settings = ({
  onSave,
  onClose,
  settingsModal,
  currentConfirmationMessage,
  defaultConfirmationMessage,
  placeholderData,
}: SettingsProps) => {
  const [editedMessage, setEditedMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    await onSave(editedMessage);
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      loading={loading}
      header={{
        icon: { library: "lucide", name: "Settings", variant: "header" },
        text: "Form settings",
      }}
      footer={{
        left: [
          {
            text: "Cancel",
            variant: "cancel",
            onClick: onClose,
          },
        ],
        right: [{ text: "Save", variant: "save", onClick: save }],
      }}
      open={settingsModal}
      onClose={onClose}
    >
      <h3>Confirmation Message</h3>
      <p>This message will be shown after the form has been submitted:</p>
      <Editor
        dataTestId="message-editor"
        initialTextValue={appendDownloadFormLink(
          currentConfirmationMessage ||
            defaultConfirmationMessage ||
            defaultTemplate.response,
        )}
        onValueChange={setEditedMessage}
        placeholders={{
          keys: templateResponsePlaceholders,
          details: templatePlaceholderDetails,
          data: placeholderData,
        }}
      />
    </Modal>
  );
};
