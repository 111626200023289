import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { IconButton } from "@smart/itops-components-dom";
import { Displayer } from "@smart/itops-editor-dom";

const BannerWrapper = styled.div`
  position: relative;
  z-index: 1;

  max-width: ${(props) => props.theme.breakPoints.maximum}px;
  margin: 1rem auto;
  padding: 0 1.4rem;

  .banner {
    background-color: ${(props) => props.theme.palette.warning.accent};
    min-height: 4rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    .banner-content {
      flex: 1;

      p {
        margin: 1rem 2rem;
        line-height: 2;
      }

      a {
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 3px 6px;
        background-color: ${(props) => props.theme.palette.background.accent};
        border: 1px solid ${(props) => props.theme.palette.background.highlight};
        color: ${(props) => props.theme.palette.foreground.base};
        text-decoration: none;
        border-radius: 4px;
        cursor: pointer;
        line-height: 1.6;
      }
    }

    .banner-dismiss-button {
      background: none;
      color: inherit;
    }
  }
`;

type BannerFlagKey = "tabBannerInfo" | "builderBannerInfo";

const dismissedBannerKey = (id: string) => `dismissed-intake-tab-banner.${id}`;

const useBannerText = (flagKey: BannerFlagKey) => {
  const [dismissed, setDismissed] = useState(false);
  const flags = useFeatureFlags();

  const bannerFlag = flags[flagKey];
  useEffect(() => {
    if (bannerFlag?.id) {
      setDismissed(!!localStorage.getItem(dismissedBannerKey(bannerFlag?.id)));
    }
  }, [bannerFlag?.id]);

  if (!bannerFlag || !bannerFlag.id || !bannerFlag.text) return null;
  if (dismissed) return null;

  return {
    text: bannerFlag.text,
    style: bannerFlag.style,
    onDismiss: () => {
      if (bannerFlag?.id) {
        localStorage.setItem(dismissedBannerKey(bannerFlag.id), "true");
      }
      setDismissed(true);
    },
  };
};

type BannerProps = {
  flagKey: BannerFlagKey;
  className?: string;
};

export const Banner = ({ flagKey, className }: BannerProps) => {
  const banner = useBannerText(flagKey);
  if (!banner) return null;

  return (
    <BannerWrapper className={className}>
      <div className="banner" style={banner.style}>
        <div className="banner-content">
          <Displayer paragraphTag="p" value={banner.text} />
        </div>
        <IconButton
          onClick={banner.onDismiss}
          className="banner-dismiss-button"
          name="cancel"
          palette="foreground"
          size={12}
          active
        />
      </div>
    </BannerWrapper>
  );
};
