import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowSelectionState,
  OnChangeFn,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { ReactNode } from "react";

import { Icon } from "@smart/itops-icons-dom";

import { TableWrapper } from "./wrapper";
import { ProgressOverlay } from "../progress";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    action?: boolean;
  }
}

type TableState<S> = { state: S; onChange: OnChangeFn<S> };

export type TableProps<I extends {}> = {
  data: I[];
  columns: ColumnDef<I, any>[];
  updating?: boolean;
  loadingRows?: number;
  getRowId?: (row: I) => string;
  select: TableState<RowSelectionState> | undefined;
  sort: TableState<SortingState> | undefined;
  globalFilter: (TableState<string> & { fn: FilterFn<I> }) | undefined;
  empty?: ReactNode;
  applyColumnSize?: boolean;
};

export const Table = <I extends {}>({
  data,
  columns,
  updating,
  loadingRows,
  getRowId,
  select,
  sort,
  globalFilter,
  empty,
  applyColumnSize,
}: TableProps<I>) => {
  const table = useReactTable({
    state: {
      rowSelection: select?.state,
      sorting: sort?.state,
      globalFilter: globalFilter?.state,
    },
    data,
    columns,
    enableRowSelection: !!select,
    onRowSelectionChange: select?.onChange,
    enableSorting: !!sort,
    onSortingChange: sort?.onChange,
    globalFilterFn: globalFilter?.fn,
    onGlobalFilterChange: select?.onChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
  });

  return (
    <TableWrapper>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  id={header.column.id}
                  title={header.column.columnDef.header?.toString()}
                  onClick={header.column.getToggleSortingHandler()}
                  className={
                    header.column.getCanSort() ? "sortable" : undefined
                  }
                  aria-sort={
                    (
                      {
                        asc: "ascending",
                        desc: "descending",
                        none: undefined,
                      } as const
                    )[header.column.getIsSorted() || "none"]
                  }
                >
                  <span className="table-header">
                    <span className="table-header-title">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </span>
                    <Icon
                      className="table-header-sort"
                      library="lucide"
                      name="ChevronUp"
                      size={16}
                      stroke={5}
                    />
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {updating && (
            <tr className="overlay">
              <td>
                <ProgressOverlay position="bottom" size={0.3} indeterminate />
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} aria-selected={row.getIsSelected()}>
              {row.getAllCells().map((cell) => (
                <td
                  key={cell.id}
                  aria-describedby={cell.column.id}
                  className={`
                    ${cell.column.columnDef.meta?.action ? "action" : "display"}
                    ${cell.row.getCanSelect() ? "selectable" : undefined}
                  `}
                  style={
                    applyColumnSize
                      ? {
                          minWidth: cell.column.getSize(),
                          width: cell.column.getSize(),
                        }
                      : undefined
                  }
                  onClick={
                    cell.column.columnDef.meta?.action
                      ? undefined
                      : row.getToggleSelectedHandler()
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {loadingRows &&
            Array.from({ length: loadingRows }, (_, i) => (
              <tr key={`loading-${i}`}>
                {table.getAllLeafColumns().map((column) => (
                  <td
                    key={column.id}
                    style={{
                      maxWidth: column.getSize(),
                      minWidth: column.getSize(),
                    }}
                  >
                    <span className="cell-skeleton">Loading</span>
                  </td>
                ))}
              </tr>
            ))}
          {!loadingRows && !!empty && table.getRowCount() === 0 && (
            <tr>
              <td colSpan={100}>
                <span className="cell-empty">{empty}</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
};
