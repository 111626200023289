import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, FieldError, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { BetaPill } from "@smart/bridge-intake-components-dom/src/components/autofill-components/beta-pill";
import { SubmissionTrackingProps } from "@smart/bridge-metrics-dom";
import {
  defaultTemplate,
  templateMessagePlaceholders,
  templatePlaceholderDetails,
} from "@smart/bridge-templates-basic";
import {
  extractFamilyProQuestionnaireType,
  FamilyProFormUri,
  familyProQuestionnaireFormUri,
  isFamilyProFormUri,
  SubmissionNS,
} from "@smart/bridge-types-basic";
import {
  Editor,
  FailureMessage,
  FieldList,
  Link,
  Modal,
  RadioButtons,
  Select,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { PlaceholderData } from "@smart/itops-serialisation-basic";
import { Matter, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { extractId } from "@smart/itops-types-basic";
import { buildLogProps } from "@smart/itops-utils-basic";
import {
  mapToMatterSubmission,
  MatterSubmission,
  submissionLink,
  useSendMatterQuestionnaire,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import {
  buildRoleContactName,
  communicationMethodLabel,
  CommunicationMethodType,
  communicationMethodValue,
  EventForwarder,
  EventLogger,
  registerCapturedEvent,
  RoleContact,
  RoleContacts,
  SubmissionForm,
  useAvailabilities,
  useFormCollections,
} from "@smart/manage-hooks-dom";

import { AllowAIFillToggle } from "../../form-builder/edit-form-v2/edit-view/allow-ai-fill-toggle";
import { GetArchieButton } from "../../form-builder/shared";
import { PreviewProps } from "../preview";

const noFormMessage = "Please select a form";
const noEmailMessage = "Email address required to share form";
const invalidEmailMessage = "Valid email address required to share form";
const noContactMessage = "Please select a contact";
const getTabCreateSubmissionSchema = (
  roleContacts: RoleContacts["roleContacts"],
  isInternal?: boolean,
) =>
  z.object({
    form: z.object(
      {
        uri: z.string(),
        title: z.string(),
      },
      {
        invalid_type_error: noFormMessage,
        required_error: noFormMessage,
      },
    ),
    contact: z
      .object(
        {
          id: z.string(),
          email: z
            .string({
              invalid_type_error: noEmailMessage,
            })
            .nullable()
            .optional(),
        },
        {
          invalid_type_error: noContactMessage,
          required_error: noContactMessage,
        },
      )
      .passthrough()
      .refine(
        ({ id }) => {
          const contact = roleContacts?.find((rc) => rc.id === id);
          if (!contact) return true;
          return !contact.company;
        },
        {
          message: "Please add an organization contact on the matter",
        },
      )
      .refine(
        ({ email }) => {
          if (email && !z.string().email().safeParse(email).success)
            return false;
          return true;
        },
        {
          message: invalidEmailMessage,
          path: ["contact.email"],
        },
      )
      .refine(
        ({ id, email }) => {
          const isCompanyContact = !!roleContacts?.find((rc) => rc.id === id)
            ?.company;
          if (!isInternal && !isCompanyContact && !email) return false;
          return true;
        },
        {
          message: noEmailMessage,
          path: ["contact.email"],
        },
      ),
    communicationMethod: z.enum(communicationMethodValue),
    template: z.string().min(1, "Please enter message"),
  });

const actionLabel: Record<CommunicationMethodType, string> = {
  email: "Open Email",
  communicate: "Share",
  internalUse: "Open",
};

export type TabCreateSubmissionForm = {
  form: (SubmissionForm & { collectionTitle: string }) | null;
  contact: RoleContact | null;
  communicationMethod: CommunicationMethodType;
  template: string;
};

export type OnSendQuestionnaire = ReturnType<
  typeof useSendMatterQuestionnaire
>[0];

export type TabCreateSubmissionProps = {
  onSendQuestionnaire: OnSendQuestionnaire;
  onAddOrUpdateEmail: (contact?: RoleContact) => void;
  onCreateSubmission: (
    detail: { uri: string; matter: Matter; aiFillFromMatterInfo: boolean },
    values: TabCreateSubmissionForm,
  ) => Promise<Gql.SubmissionFieldsFragment>;
  onEditSubmission: (submission: MatterSubmission) => void;
  onMessage: (
    values: TabCreateSubmissionForm,
    placeholderData: PlaceholderData,
    trackingInfo: SubmissionTrackingProps,
  ) => void;
  onClose: () => void;
  logEvent: EventLogger;
  sharing: "regular" | "internal" | undefined;
  sharingFormUri?: string;
  Preview: (props: PreviewProps) => JSX.Element;
  matter: Matter;
  matterType: { name: string; location: string };
  matchedMatterType?: { id: string; location: string; name: string };
  roleContacts: RoleContacts;
  isFamilyProAuthorized: boolean;
  isFamilyProFormShared: boolean;
  teamForms: SubmissionForm[];
  matterSpecificForms: SubmissionForm[];
  globalForms: SubmissionForm[];
  teamCategoryForms: SubmissionForm[];
  globalCategoryForms: SubmissionForm[];
  settingTemplate?: string;
  team: { name: string; phone: string };
};

export const TabCreateSubmission = ({
  onSendQuestionnaire,
  onAddOrUpdateEmail,
  onCreateSubmission,
  onEditSubmission,
  onMessage,
  onClose,
  logEvent,
  sharing,
  sharingFormUri,
  Preview,
  matter,
  matterType,
  matchedMatterType,
  roleContacts: { client, roleContacts },
  isFamilyProAuthorized,
  isFamilyProFormShared,
  teamForms,
  matterSpecificForms,
  globalForms,
  teamCategoryForms,
  globalCategoryForms,
  settingTemplate,
  team,
}: TabCreateSubmissionProps) => {
  const { staffPopulateIntakeWithFile, archieFeature } = useFeatureFlags();
  const [submissionUri] = useState(() => SubmissionNS.generateUri());
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();
  const [autofillToggle, setAutofillToggle] = useState(false);
  const availabilities = useAvailabilities({ matter, isFamilyProAuthorized });
  const formCollections = useFormCollections({
    availabilities,
    teamForms,
    matterSpecificForms,
    globalForms,
    teamCategoryForms,
    globalCategoryForms,
    matterTypeId: matter.matterTypeId,
    matterNumber: matter.number,
    teamName: team.name,
    matterType,
    matchedMatterType,
    isFamilyProFormShared,
  });
  const { hostType } = useSmokeballApp();

  const defaultTemplateToUse =
    settingTemplate?.trim() ||
    defaultTemplate[matter.isLead ? "lead" : "matter"];

  const isInternal = sharing === "internal";
  const form = useForm<TabCreateSubmissionForm>({
    defaultValues: {
      form: null,
      contact: null,
      communicationMethod: isInternal ? "internalUse" : "communicate",
      template: defaultTemplateToUse,
    },
    resolver: zodResolver(
      getTabCreateSubmissionSchema(roleContacts, isInternal),
    ),
    mode: "onChange",
  });
  const { handleSubmit, control, watch, getValues, setValue, trigger } = form;

  useEffect(
    () =>
      logEvent({
        type: "start-sending-submission",
        activity: "send-submission",
        submissionId: extractId(submissionUri),
      }),
    [],
  );

  useEffect(() => {
    const current = getValues("contact.id");
    if (current) {
      setValue(
        "contact",
        roleContacts?.find((rc) => current === rc.id) || null,
      );
    } else if (client) {
      setValue("contact", client);
    } else if (roleContacts?.[0]) {
      setValue("contact", roleContacts[0]);
    }
    trigger("contact").catch(console.error);
    trigger("contact.email").catch(console.error);
  }, [client, roleContacts]);

  const contact = watch("contact");
  const formUri = watch("form")?.uri;
  const formTitle = watch("form")?.title;
  const communicationMethod = watch("communicationMethod");

  useEffect(() => {
    if (
      formUri ||
      (!availabilities.availableForLead && !availabilities.availableForMatter)
    )
      return;

    if (sharingFormUri) {
      const sharingForm = matterSpecificForms.find(
        (f) => f.uri === sharingFormUri,
      );
      if (sharingForm)
        setValue("form", { ...sharingForm, collectionTitle: "" });
    } else if (teamForms.length === 1) {
      setValue("form", { ...teamForms[0], collectionTitle: "" });
    } else if (teamForms.length === 0 && globalForms.length === 1) {
      setValue("form", { ...globalForms[0], collectionTitle: "" });
    }
  }, [teamForms, globalForms, formUri]);

  useEffect(() => {
    if (
      formUri === familyProQuestionnaireFormUri &&
      communicationMethod === "internalUse"
    ) {
      setValue("communicationMethod", "communicate");
    }
  }, [formUri]);

  useEffect(() => {
    trigger("contact.email").catch(console.error);
  }, [communicationMethod]);

  const placeholderData = {
    clientName: contact?.person?.firstName || contact?.company?.name || "",
    clientEmail: contact?.person?.email || contact?.company?.email || "",
    matterCategory: matter.isLead ? "lead" : "matter",
    formCreatedAt: "",
    matterType: matterType.name,
    firmName: team.name,
    firmPhone: team.phone,
    formTitle: formTitle || "",
  };

  const onSubmit = handleSubmit(async (values) => {
    setHasError(undefined);
    setIsLoading(true);
    try {
      const isInternalUse = values.communicationMethod === "internalUse";
      const createAndSend = async () => {
        let formLink: string | undefined;
        let submission;
        if (values.form && isFamilyProFormUri(values.form.uri)) {
          const email = values.contact?.email;
          if (!email && !isInternalUse) throw new Error("Please add an email");

          const result = await onSendQuestionnaire({
            variables: {
              matterId: matter.id,
              clientName:
                [contact?.person?.firstName, contact?.person?.lastName]
                  .filter(Boolean)
                  .join(" ") ||
                contact?.company?.name ||
                "Intake Placeholder Name",
              email: email || "placeholder@intake.com",
              party: "a",
              questionnaireType: extractFamilyProQuestionnaireType(
                values.form.uri as FamilyProFormUri,
              ),
            },
          });

          if (isInternalUse) {
            onEditSubmission({
              formUri: familyProQuestionnaireFormUri,
              externalSubmissionEmbedUrl:
                result.data?.sendMatterQuestionnaire.embedUrl,
            } as MatterSubmission);
            return;
          }

          formLink = result.data?.sendMatterQuestionnaire.link;
        } else {
          submission = await onCreateSubmission(
            {
              uri: submissionUri,
              matter,
              aiFillFromMatterInfo: autofillToggle,
            },
            values,
          );

          if (isInternalUse && submission) {
            onEditSubmission(mapToMatterSubmission(submission));
            return;
          }

          formLink = submissionLink(submission);
        }

        if (!formLink) throw new Error(`Failed to generate link`);

        onMessage(
          values,
          {
            ...placeholderData,
            formLink,
          },
          {
            submissionUri: submission
              ? submission.uri
              : `${matter.id}-questionnaire-party-a`,
            formUri: submission
              ? submission.form.uri
              : familyProQuestionnaireFormUri,
            matterCategory: matter.isLead ? "lead" : "matter",
            matterTypeId: matter.matterTypeId,
            matterTypeName: placeholderData.matterType,
          },
        );
      };

      await createAndSend();
      onClose();
    } catch (e) {
      const { logMessage, errorProps } = buildLogProps(e);
      setHasError(`${logMessage} - ${errorProps.message}`);
    }
    setIsLoading(false);
  });

  const previewColumn = (
    <FieldList className="h-full">
      <Controller
        control={control}
        name="form"
        render={({ field, fieldState }) => (
          <Select
            id={field.name}
            dataTestId="select-form"
            className="z-10"
            label="Form"
            options={formCollections
              .filter((c) => c.key !== "familyPro" && c.options.length)
              .map((c) => ({
                heading: c.title,
                items: c.options.map((o) => ({
                  label: o.title,
                  value: o,
                })),
              }))}
            value={field.value}
            onChange={(v) => field.onChange(v)}
            valueComparer={(o, v) => o?.uri === v?.uri}
            message={
              fieldState.error?.message ||
              "Responses will automatically be saved to the matter on completion"
            }
            error={!!fieldState.error}
            empty="No forms available"
            mandatory
          />
        )}
      />
      {staffPopulateIntakeWithFile &&
        isInternal &&
        archieFeature !== "unavailable" &&
        hostType !== "web" && (
          <AllowAIFillToggle
            label="Autofill this form with Archie"
            tooltip="Archie pre-populates form fields using your matter data, saving your client time."
            toggled={autofillToggle}
            loading={false}
            onClick={async () => setAutofillToggle(!autofillToggle)}
            disabled={archieFeature === "available"}
            right={
              <>
                <BetaPill />
                <GetArchieButton />
              </>
            }
          />
        )}
      <div
        className={`${isInternal ? "h-[61rem]" : "h-[48.4rem]"} flex flex-col items-center justify-center z-0`}
      >
        <Preview
          matter={matter}
          formUri={formUri}
          submissionUri={submissionUri}
          matchedMatterType={matchedMatterType}
          recipientContactId={contact?.id}
        />
      </div>
    </FieldList>
  );
  const modal = (
    <Modal
      size={isInternal ? "default" : "xLarge"}
      header={{
        icon: isInternal ? "solidPaperPlaneTop" : "solidShare",
        text: isInternal ? "Select form to prefill" : "Share Form",
        iconBackgroundColor: "cyan",
      }}
      open={!!sharing}
      onClose={onClose}
      footer={{
        buttons: [
          {
            text: "Cancel",
            type: "reset",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: autofillToggle
              ? "Autofill and open form"
              : actionLabel[communicationMethod],
            type: "submit",
            onClick: onSubmit,
            eventCaptureAttributes: {
              ...registerCapturedEvent({
                submission: { uri: submissionUri },
                form: formUri ? { uri: formUri } : undefined,
                eventDataType: "send-submission",
                eventType: "click",
                activityType: "send-submission",
              }),
            },
          },
        ],
      }}
      loading={isLoading}
      closeOptions={{ clickOutside: false }}
    >
      <FormProvider {...form}>
        {isInternal ? (
          previewColumn
        ) : (
          <div className="flex gap-[2.4rem]">
            <div className="w-[61.6rem] pr-[2.4rem] border-r border-neutral-100">
              {previewColumn}
            </div>
            <div className="w-[61.6rem]">
              <div>
                <FieldList>
                  <Controller
                    name="contact"
                    render={({ field, fieldState }) => {
                      const emailErrorMessage = (
                        fieldState.error as {
                          contact?: { email?: FieldError };
                        }
                      )?.contact?.email?.message;

                      return (
                        <Select
                          id={field.name}
                          dataTestId="select-contact"
                          label="Contact"
                          options={(roleContacts || []).map((c) => ({
                            label: buildRoleContactName(c),
                            value: c,
                          }))}
                          value={field.value}
                          onChange={(v) => field.onChange(v)}
                          valueComparer={(o, v) => o?.id === v?.id}
                          error={!!fieldState.error && !emailErrorMessage}
                          message={fieldState.error?.message}
                          empty="No contacts available"
                          mandatory
                        />
                      );
                    }}
                  />
                  <Controller
                    name="contact"
                    render={({ field, fieldState }) => {
                      const errorMessage = (
                        fieldState.error as {
                          contact?: { email?: FieldError };
                        }
                      )?.contact?.email?.message;

                      return (
                        <TextFieldInput
                          id={field.name}
                          label="Email"
                          value={field.value?.email || ""}
                          error={!!fieldState.error}
                          message={
                            errorMessage ? (
                              <span className="flex gap-medium items-center">
                                <div>{errorMessage}</div>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                  text={`${
                                    field.value?.email
                                      ? "Update email address for"
                                      : "Add email address to"
                                  } contact`}
                                  onClick={() =>
                                    onAddOrUpdateEmail(
                                      form.getValues("contact") || undefined,
                                    )
                                  }
                                />
                              </span>
                            ) : undefined
                          }
                          disabled
                          readOnly
                          mandatory
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="communicationMethod"
                    render={({ field, fieldState }) => (
                      <RadioButtons
                        id={field.name}
                        label="Send via"
                        error={!!fieldState.error}
                        message={fieldState.error?.message}
                        options={communicationMethodValue
                          .filter((method) => method !== "internalUse")
                          .map((value) => ({
                            value,
                            label: communicationMethodLabel[value],
                          }))}
                        mandatory
                        {...field}
                      />
                    )}
                  />
                  {communicationMethod !== "internalUse" && (
                    <Controller
                      control={control}
                      name="template"
                      render={({ field, fieldState }) => (
                        <Editor
                          id={field.name}
                          label="Message"
                          error={!!fieldState.error}
                          message={fieldState.error?.message}
                          initialTextValue={field.value}
                          onValueChange={field.onChange}
                          placeholders={{
                            keys: templateMessagePlaceholders,
                            details: templatePlaceholderDetails,
                            data: placeholderData,
                          }}
                          dataTestId="message-editor"
                          mandatory
                        />
                      )}
                    />
                  )}
                  {hasError && (
                    <FailureMessage
                      action={
                        communicationMethod === "internalUse"
                          ? "open form"
                          : "share form"
                      }
                      title={hasError}
                    />
                  )}
                </FieldList>
              </div>
            </div>
          </div>
        )}
      </FormProvider>
    </Modal>
  );

  return (
    <EventForwarder
      eventTypes={["input", "click", "focus"]}
      eventDataTypeMap={{
        input: "type",
        click: "click",
        focus: "focus",
      }}
      form={formUri ? { uri: formUri } : undefined}
      submission={{ uri: submissionUri }}
      activityType="send-submission"
    >
      {modal}
    </EventForwarder>
  );
};
