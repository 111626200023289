import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { Button, EditableText, MultipleLabel } from "@smart/itops-ui-dom";

import { EditFormInfoModal } from "./edit-form-info-modal";
import { EditingFormInfo } from "../../list-forms/form";
import {
  GqlField,
  GqlFormCategory,
  GqlGroup,
  GqlMatterType,
  GqlSetting,
} from "../../types";

const FormInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const FormInfoButton = styled(Button)`
  padding: 0;
  border: 0;

  .button-icon {
    color: ${(props) => props.theme.scheme.grey.r50};
    opacity: 0;
  }

  :hover:not(:disabled) {
    .button-icon {
      opacity: 1;
    }
  }
`;

const Label = styled(MultipleLabel)`
  color: ${(props) => props.theme.scheme.grey.r50};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 350;
  margin: 0.1rem 0.6rem;
  width: 100%;
`;

const FormInfoLabel = ({
  labels,
  loading,
}: {
  labels: string[][];
  loading: boolean;
}) => (
  <Label
    labels={labels}
    placeholders={["Matter types", "Labels"]}
    loading={loading}
    maxVisibleItems={2}
  />
);

type FormInfoProps = {
  formTitle: string;
  matterTypeNames: string[];
  matterTypeLocations: string[];
  disabled?: boolean;
  isMatterSpecific?: boolean;
  loading: {
    form: boolean;
    matterTypes: boolean;
  };
  onFormTitleUpdate: (title: string) => Promise<void>;
  validateFormTitle: (title: string) => boolean;
  updateFormInfo: (values: EditingFormInfo) => Promise<void>;
  setting?: GqlSetting | null;
  category?: GqlFormCategory;
  matterTypes: GqlMatterType[];
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
};

const FormInfo = ({
  formTitle,
  matterTypeNames,
  matterTypeLocations,
  disabled,
  isMatterSpecific,
  loading,
  onFormTitleUpdate,
  validateFormTitle,
  updateFormInfo,
  setting,
  category,
  matterTypes,
  groups,
  fields,
}: FormInfoProps) => {
  const [editingTitle, setEditingTitle] = useState(formTitle);
  const [titleError, setTitleError] = useState<string>();
  const [enterEditing, setEnterEditing] = useState(false);
  const [openEditFormInfoModal, setOpenEditFormInfoModal] = useState(false);

  const isLoading =
    (matterTypeNames.length === 0 || matterTypeLocations.length === 0) &&
    (loading.matterTypes || loading.form);

  const labels = [matterTypeNames, matterTypeLocations];

  const onEditingTitleComplete = async () => {
    setEnterEditing(false);

    if (!editingTitle) {
      setTitleError("Please enter a name.");
      return;
    }

    if (!validateFormTitle(editingTitle)) {
      setTitleError(
        "A form with this name already exists. Please enter another name.",
      );
      return;
    }

    await onFormTitleUpdate(editingTitle);
  };

  useEffect(() => {
    setEditingTitle(formTitle);
  }, [formTitle]);

  return (
    <FormInfoWrapper data-testid="form-title-edit">
      <EditableText
        text={editingTitle}
        placeholder="Form title"
        error={titleError}
        editing={enterEditing}
        loading={loading.form && !formTitle}
        disabled={disabled}
        dataTestId="editor-form-title"
        onChange={(value) => {
          setTitleError(undefined);
          setEditingTitle(value);
        }}
        onComplete={onEditingTitleComplete}
        fontSize="heading"
        bold
      />
      <FormInfoButton
        dataTestId="edit-form-info-button"
        icon={{ size: 17, library: "lucide", name: "Pencil" }}
        kind="borderless"
        onClick={() => setOpenEditFormInfoModal(true)}
        disabled={disabled || isMatterSpecific}
        left={<FormInfoLabel labels={labels} loading={isLoading} />}
      />
      {openEditFormInfoModal && (
        <EditFormInfoModal
          loading={loading.form || loading.matterTypes}
          open={openEditFormInfoModal}
          onClose={() => setOpenEditFormInfoModal(false)}
          updateFormInfo={updateFormInfo}
          setting={setting}
          category={category}
          matterTypeLocations={matterTypeLocations}
          matterTypes={matterTypes}
          groups={groups}
          fields={fields}
        />
      )}
    </FormInfoWrapper>
  );
};

export { FormInfo, FormInfoProps };
