import styled from "@emotion/styled";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { Icon } from "@smart/itops-icons-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import { Combobox, CurrencyInput, FieldList } from "@smart/itops-ui-dom";
import { useLoadBankAccounts } from "@smart/manage-gql-client-dom";

import { GqlFieldValues, OnUpdateField } from "../../types";

const PaymentInfo = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin: 1rem 0.5rem;
  color: ${(props) => props.theme.scheme.blue.r100};

  p {
    font-weight: 600;
    margin: 0;
  }
`;

const FieldListContainer = styled(FieldList)`
  gap: 1rem;
  margin: 1rem 0;
`;

type PaymentProps = {
  onUpdateField: OnUpdateField;
  field: GqlFieldValues;
};

export const Payment = ({
  field: fieldValues,
  onUpdateField,
}: PaymentProps) => {
  const bankAccounts = useLoadBankAccounts({});
  const { amountInCents, bankAccount } = fieldValues.payment || {};

  const formMethods = useForm<{
    amountInCents?: number | null;
    bankAccount?: {
      id: string;
      type: number;
    } | null;
  }>({
    defaultValues: {
      amountInCents,
      bankAccount,
    },
  });

  const { control, handleSubmit, getValues, setValue } = formMethods;

  const submit = handleSubmit(async (values) => {
    await onUpdateField({
      field: fieldValues,
      updated: {
        payment: {
          amountInCents: values.amountInCents,
          bankAccount: values.bankAccount,
        },
      },
    });
  });

  useEffect(() => {
    if (bankAccounts.result?.length && !getValues("bankAccount.id")) {
      setValue("bankAccount", bankAccounts.result[0].identifier);
      submit().catch(console.error);
    }
  }, [bankAccounts.result]);

  const emptyText = bankAccounts.loading
    ? "loading..."
    : "No accounts linked to Smokeball Payments";

  return (
    <FormProvider {...formMethods}>
      <PaymentInfo>
        <Icon library="lucide" name="Info" />
        <p>Can only be used with Smokeball Payments</p>
      </PaymentInfo>
      <FieldListContainer size="emphasis">
        <Controller
          control={control}
          name="amountInCents"
          render={({ field }) => (
            <CurrencyInput
              placeholder="Amount"
              intlConfig={loadLocale()}
              defaultValue={field.value ? field.value / 100 : undefined}
              onValueChange={(_, __, values) => {
                if (values?.float) field.onChange(values.float * 100);
              }}
              onBlur={submit}
            />
          )}
        />
        <Controller
          control={control}
          name="bankAccount"
          render={({ field, fieldState }) => (
            <Combobox
              id={field.name}
              title="Account"
              options={bankAccounts.result || []}
              empty={emptyText}
              getOptionLabel={(account) =>
                account.displayName ||
                account.name ||
                `${account.typeDisplayName} - Account`
              }
              error={!!fieldState.error}
              value={
                // Fall back to null so the input doesn't change between controlled and uncontrolled
                (bankAccounts.result?.find(
                  (account) => account.identifier?.id === field.value?.id,
                ) || null) as NonNullable<
                  ReturnType<typeof useLoadBankAccounts>["result"]
                >[number]
              }
              onChange={async (_, account) => {
                field.onChange(account.identifier);
                await submit();
              }}
              placeholder={emptyText}
              disableClearable
              multiple={false}
            />
          )}
        />
      </FieldListContainer>
    </FormProvider>
  );
};
