import { useState } from "react";

import { buildLogProps, isElementOf } from "@smart/itops-utils-basic";
import { MatterSubmission } from "@smart/manage-gql-client-dom";
import { FailureMessage, Modal } from "@smart/itops-sb-design-system-dom";

export type SubmissionActionDeleteProps = {
  selected?: MatterSubmission;
  onConfirm: (submission: MatterSubmission) => Promise<void>;
  onClose: () => void;
  onViewClose: () => void;
};

const isDeletableStatus = isElementOf([
  "loading",
  "loaded",
  "failedToLoad",
] as const);
const canDeleteSubmission = (submission?: MatterSubmission): boolean => {
  // Non-existent submission can't be deleted
  if (!submission) return false;

  // Statuses before notSynced can be deleted
  if (isDeletableStatus(submission.syncStatus)) return true;

  // After that, nothing can be deleted
  return false;
};

export const SubmissionActionDelete = ({
  selected,
  onConfirm,
  onClose,
  onViewClose,
}: SubmissionActionDeleteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();

  const formTitle = selected?.formTitle || "this form";
  const canDelete = canDeleteSubmission(selected);
  const statusAction = selected?.trackers?.find(
    (t) => t?.action === "saved-to-matter",
  )
    ? `saved for "${formTitle}"`
    : `entered into "${formTitle}"`;

  const onSubmit = async () => {
    setHasError(undefined);
    setIsLoading(true);
    try {
      if (!selected) throw new Error(`Please select a form`);
      if (!canDeleteSubmission(selected))
        throw new Error(`Invalid delete status: ${selected?.syncStatus}`);

      await onConfirm(selected);
      onClose();
      onViewClose();
    } catch (e) {
      const { logMessage, errorProps } = buildLogProps(e);
      setHasError(`${logMessage} - ${errorProps.message}`);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      header={{
        icon: canDelete ? "solidTrashCan" : "solidTriangleExclamation",
        text: canDelete ? "Delete form" : "Form cannot be deleted",
      }}
      open={!!selected}
      footer={{
        buttons: canDelete
          ? [
              {
                text: "Cancel",
                type: "reset",
                variant: "secondarySubtle",
                onClick: () => onClose(),
              },
              {
                text: "Yes, delete",
                type: "submit",
                onClick: () => onSubmit(),
              },
            ]
          : [
              {
                text: "OK",
                type: "reset",
                onClick: () => onClose(),
              },
            ],
      }}
      closeOptions={{
        escapeKey: false,
        clickOutside: false,
      }}
      loading={isLoading}
    >
      {canDelete ? (
        <>
          <p>
            Deleting &quot;{formTitle}&quot; will prevent the client from
            entering responses.
          </p>
          <p>Are you sure you want to delete the form?</p>
          {hasError && <FailureMessage action="delete form" title={hasError} />}
        </>
      ) : (
        <p>
          Responses have been {statusAction} and therefore the form cannot be
          deleted.
        </p>
      )}
    </Modal>
  );
};
