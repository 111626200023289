import { Global, css, Theme } from "@emotion/react";

import { interCss } from "@smart/itops-fonts-dom";

const globalCss = (theme: Theme | undefined) => css`
  html {
    box-sizing: border-box;
    font-family: ${theme?.fonts.body || "sans-serif"};
    font-size: ${theme?.baseFontSize || "62.5%"};
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: ${theme?.scheme.grey.r15};
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme?.scheme.grey.r40};
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme?.scheme.grey.r60};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
    color: inherit;
  }

  body {
    font-family: ${theme?.fonts.body};
    color: ${theme?.scheme.grey.r100};
    margin: 0;
    padding: 0;
  }

  :root {
    --background: white;
  }

  h1 {
    font-family: ${theme?.fonts.title};
  }

  h2 {
    font-size: ${theme?.fontSize.subHeading};
  }

  p {
    font-size: ${theme?.fontSize.base};
  }
`;

export const UIStyles = () => <Global styles={[globalCss, interCss]} />;

const bodyCss = (theme: Theme | undefined) => css`
  body {
    --background: ${theme?.scheme.grey.r20};
    background: var(--background);
  }

  p {
    line-height: 1.6;
  }

  hr {
    border: 0;
    border-bottom: 1px solid ${theme?.scheme.grey.r25};
    margin: 2rem 0;
  }
`;

export const BodyStyles = () => <Global styles={[bodyCss]} />;
