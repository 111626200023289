import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEvent } from "react";
import { twMerge } from "tailwind-merge";

import { archie } from "@smart/bridge-images-dom";

import { availableIcons, IconName } from "./available-icons";

type IconProps = {
  name: IconName;
  className?: string;
  onClick?: (event: MouseEvent<SVGSVGElement>) => void;
  dataTestId?: string;
};

export const Icon = ({ name, className, dataTestId, onClick }: IconProps) => {
  if (name === "archie") {
    return (
      <img src={archie} alt="Archie" className="w-8" data-testid={dataTestId} />
    );
  }

  return (
    <FontAwesomeIcon
      icon={availableIcons[name]}
      className={twMerge("w-[1.6rem] h-[1.6rem]", className)}
      onClick={onClick}
      data-testid={dataTestId}
    />
  );
};

export type { IconName };
