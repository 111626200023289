import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { Icon } from "@smart/itops-icons-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { Button } from "@smart/itops-ui-dom";

export const WindowCloser = () => {
  const { formBuilderV2Closer } = useFeatureFlags();
  const { host, hostType } = useSmokeballApp();

  return (
    formBuilderV2Closer &&
    hostType !== "windows" && (
      <Button
        left={<Icon library="lucide" name="X" />}
        size="base"
        variant="cancel"
        kind="input"
        onClick={() => host?.close()}
      />
    )
  );
};
