import { clsx } from "clsx";

import { Button } from "../button";
import { Icon, IconName } from "../icon";

export type ModalHeaderProps = {
  icon?: IconName;
  iconBackgroundColor?: "orange" | "cyan" | "green";
  text?: string;
  onClose?: () => void;
};

export const ModalHeader = ({
  icon,
  iconBackgroundColor,
  text,
  onClose,
}: ModalHeaderProps) => {
  const iconBgColors = {
    orange: "bg-orange-200",
    cyan: "bg-cyan-140",
    green: "bg-green-100",
  };

  return (
    <div className="flex justify-between items-center gap-medium h-[6.4rem] px-[2.4rem] py-[1.2rem]">
      {icon && (
        <div
          className={clsx(
            "rounded w-[4rem] h-[4rem] flex justify-center items-center",
            iconBgColors[iconBackgroundColor || "orange"],
          )}
        >
          <Icon className="w-[2.2rem] h-[2.2rem]" name={icon} />
        </div>
      )}
      {text && <h2 className="text-h3 font-poppins flex-1">{text}</h2>}
      {onClose && (
        <Button
          leftIcon="solidXmarkLarge"
          aria-label="Close Modal - X"
          variant="secondarySubtle"
          onClick={() => onClose()}
        />
      )}
    </div>
  );
};
