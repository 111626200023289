import { Draggable, Droppable } from "@hello-pangea/dnd";

import { FieldComponentList, FieldComponent } from "./field-component";
import { NavigationWrapper } from "./wrapper";
import { fieldComponentsDroppableId, fieldTemplates } from "../constants";
import { FieldTemplate } from "../types";

type FieldComponentsProps = {
  disabled?: boolean;
  excluding?: FieldTemplate[];
};

const FieldComponents = ({ disabled, excluding }: FieldComponentsProps) => (
  <NavigationWrapper>
    <h2>Components</h2>
    <Droppable droppableId={fieldComponentsDroppableId} isDropDisabled>
      {(provided) => (
        <FieldComponentList ref={provided.innerRef}>
          {fieldTemplates
            .filter(
              (template) => !excluding?.find((t) => t.type === template.type),
            )
            .map((template, index) => (
              <Draggable
                key={template.type}
                draggableId={`field-component-${template.type}`}
                isDragDisabled={disabled}
                index={index}
              >
                {(draggableProvided, snapshot) => (
                  <>
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={
                        snapshot.isDragging
                          ? {
                              ...draggableProvided.draggableProps.style,
                              transform:
                                snapshot.isDropAnimating &&
                                !snapshot.draggingOver
                                  ? undefined
                                  : draggableProvided.draggableProps.style
                                      ?.transform,
                            }
                          : undefined
                      }
                    >
                      <FieldComponent
                        type={template.type}
                        isDragging={snapshot.isDragging}
                        disabled={disabled}
                      />
                    </div>
                    {snapshot.isDragging && (
                      <FieldComponent type={template.type} disabled />
                    )}
                  </>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </FieldComponentList>
      )}
    </Droppable>
  </NavigationWrapper>
);

export { FieldComponents };
