import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { IconDetail } from "@smart/itops-icons-dom";
import { UIKey } from "@smart/itops-ui-dom";

import { FieldTemplate } from "./types";

export const fieldTemplates: FieldTemplate[] = [
  { type: "mapped" },
  { type: "text" },
  { type: "multilineText" },
  { type: "date" },
  { type: "choice" },
  { type: "checkbox" },
  { type: "currency" },
  { type: "address" },
  { type: "phoneNumber" },
  { type: "email" },
  { type: "file" },
  { type: "info" },
  { type: "number" },
  { type: "group" },
  { type: "appointment" },
  { type: "payment" },
];

export const fieldColor: Record<FieldComponentType, UIKey> = {
  mapped: "green",
  text: "blue",
  multilineText: "blue",
  choice: "blue",
  yesNo: "blue",
  date: "blue",
  currency: "blue",
  address: "blue",
  phoneNumber: "blue",
  email: "blue",
  file: "blue",
  info: "blue",
  number: "blue",
  checkbox: "blue",
  group: "blue",
  appointment: "blue",
  payment: "blue",
};

export const fieldIcon: Record<FieldComponentType, IconDetail> = {
  mapped: { library: "lucide", name: "FolderInput" },
  text: { library: "lucide", name: "PenLine" },
  multilineText: { library: "lucide", name: "WrapText" },
  choice: { library: "lucide", name: "List" },
  yesNo: { library: "lucide", name: "ToggleLeft" },
  date: { library: "lucide", name: "Calendar" },
  currency: { library: "lucide", name: "DollarSign" },
  address: { library: "lucide", name: "MapPin" },
  phoneNumber: { library: "lucide", name: "Phone" },
  email: { library: "lucide", name: "Mail" },
  file: { library: "lucide", name: "FilePlus" },
  info: { library: "lucide", name: "FileText" },
  number: { library: "lucide", name: "Hash" },
  checkbox: { library: "lucide", name: "ListTodo" },
  group: { library: "lucide", name: "Group" },
  appointment: { library: "lucide", name: "CalendarClock" },
  payment: { library: "lucide", name: "Receipt" },
};

export const fieldComponentsDroppableId = "droppable-field-components";
export const fieldSeparatorDroppableIdRegex =
  /droppable-field-separator\|[0-9]+/;
export const sectionItemsDroppableId = "droppable-section-items";
export const sectionNavigationDroppableId = "droppable-section-navigation";
export const groupFieldsDroppableIdPrefix = "droppable-group-fields";
export const groupSeparatorDroppableIdRegex =
  /^smart:group:[0-9a-zA-Z-]+\|\d+$/;
export const sectionItemContainerDroppablePrefix =
  "droppable-section-item-container";
