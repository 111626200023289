import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { defaultAIFillSettings } from "@smart/bridge-types-basic";
import { Icon } from "@smart/itops-components-dom";
import { Card, EditableText, FileUpload } from "@smart/itops-ui-dom";

import { GqlForm, UpdateAIFillSettings } from "../../types";

const defaultMaxNumOfFiles = 5;

const AIFillFileUploadWrapper = styled.div`
  max-width: ${(props) => props.theme.breakPoints.readable}px;
  margin: auto;

  .description {
    margin-bottom: 1rem;
  }

  .warning {
    display: flex;
    gap: 1rem;
    padding: 2rem;
    background: ${(props) => props.theme.scheme.yellow.r40};
    margin-bottom: 2rem;

    p {
      margin: 0;
      line-height: 2rem;
    }
  }
`;

type AIFillFileUploadProps = {
  aiFillSettings: GqlForm["aiFillSettings"];
  updateAIFillSettings: UpdateAIFillSettings;
};

export const AIFillFileUpload = ({
  aiFillSettings,
  updateAIFillSettings,
}: AIFillFileUploadProps) => {
  const { aiWordSupport } = useFeatureFlags();
  const [editingDescription, setEditingDescription] = useState(
    aiFillSettings?.fileUploadDescription ||
      defaultAIFillSettings.fileUploadDescription,
  );

  useEffect(() => {
    setEditingDescription(
      aiFillSettings?.fileUploadDescription ||
        defaultAIFillSettings.fileUploadDescription,
    );
  }, [aiFillSettings?.fileUploadDescription]);

  const sizeLimit = aiWordSupport
    ? `Max ${defaultMaxNumOfFiles} files; Jpeg, pdf, png, doc and docx; Max 5MB each.`
    : `Max ${defaultMaxNumOfFiles} files; Jpeg, pdf or png; Max 5MB each.`;

  return (
    <AIFillFileUploadWrapper>
      <div className="warning">
        <Icon library="lucide" name="AlertTriangle" size={20} />
        <p>
          Please revise text below to give clients enough information to choose
          the correct documents to upload.
        </p>
      </div>
      <Card breakPoint="readable">
        <div className="description">
          <EditableText
            dataTestId="ai-fill-file-upload-description"
            text={editingDescription}
            onChange={setEditingDescription}
            onComplete={() =>
              updateAIFillSettings({
                fileUploadDescription: editingDescription,
              })
            }
            fontSize="emphasis"
            isEditor
          />
        </div>
        <FileUpload
          id=""
          errorId=""
          sizeLimit={sizeLimit}
          onSelect={() => {}}
        />
      </Card>
    </AIFillFileUploadWrapper>
  );
};
