import styled from "@emotion/styled";

import { Icon } from "@smart/itops-components-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

const ActingAsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  font-size: ${(props) => props.theme.fontSize.emphasis};
  color: ${(props) => props.theme.scheme.orange.r60};
`;

export const ActingAsLabel = () => {
  const { actingTeam } = useManageFormsContext();
  return (
    actingTeam?.name && (
      <ActingAsTitle>
        <Icon size={25} library="lucide" name="AlertCircle" />
        <span data-testid="acting-as-firm">Acting as {actingTeam.name}</span>
      </ActingAsTitle>
    )
  );
};
