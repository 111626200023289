import styled from "@emotion/styled";

import { fieldFallbackLabel } from "@smart/bridge-intake-components-dom";
import { DraggableItemProps } from "@smart/itops-components-dom";
import { Icon } from "@smart/itops-icons-dom";
import { EditableText, Button } from "@smart/itops-ui-dom";

import { UpdatedSection } from "../../types";

const MovableSectionWrapper = styled.div<{ deleted?: boolean }>`
  display: ${(props) => (props.deleted ? "none" : "flex")};
  flex-flow: row wrap;
  align-items: center;

  --background: ${(props) => props.theme.scheme.grey.r15};
  background: var(--background);
  border-radius: 0.5rem;
  gap: 1rem;
  margin: 0.4rem 0;
  padding: 0.8rem;

  .section-item-edit {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .section-item-buttons {
    display: flex;
    gap: 0.6rem;
    --background: white;
    color: ${(props) => props.theme.scheme.grey.r60};
  }

  &:not([draggable="true"]) {
    .section-drag-handle {
      visibility: hidden;
      pointer-events: none;
    }
  }
`;

export type MovableSectionEditProps = {
  numOfSections: number;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  setTitle: (index: number, title: string) => void;
  setDeleted: (index: number, deleted: boolean) => void;
  newSectionUri?: string;
};

type MovableSectionProps = DraggableItemProps<UpdatedSection> &
  MovableSectionEditProps;

const MovableSection = ({
  index,
  item,
  draggableProvided,
  moveUp,
  moveDown,
  setTitle,
  setDeleted,
  numOfSections,
  newSectionUri,
}: MovableSectionProps) => (
  <MovableSectionWrapper
    ref={draggableProvided?.innerRef}
    {...draggableProvided?.draggableProps}
    draggable={numOfSections > 1}
    deleted={item.deleted}
  >
    <div
      {...draggableProvided?.dragHandleProps}
      className="section-drag-handle"
    >
      <Icon library="lucide" name="GripVertical" />
    </div>
    <div className="section-item-edit">
      <EditableText
        text={item.title}
        placeholder={fieldFallbackLabel.section}
        editing={newSectionUri ? newSectionUri === item.uri : undefined}
        onChange={(value) => setTitle(index, value)}
        onComplete={async () => {}}
        fontSize="subHeading"
        bold
      />
    </div>
    <div className="section-item-buttons">
      <Button
        kind="borderless"
        icon={{ library: "lucide", name: "ChevronUp" }}
        dataTestId={`move-up-button-${item.uri}`}
        disabled={index === 0}
        onClick={() => moveUp(index)}
      />
      <Button
        kind="borderless"
        icon={{ library: "lucide", name: "ChevronDown" }}
        dataTestId={`move-down-button-${item.uri}`}
        disabled={index + 1 === numOfSections}
        onClick={() => moveDown(index)}
      />
      <Button
        kind="borderless"
        icon={{ library: "lucide", name: "Trash2" }}
        dataTestId={`delete-section-button-${item.uri}`}
        onClick={() => setDeleted(index, true)}
      />
    </div>
  </MovableSectionWrapper>
);

export { MovableSection };
