import styled from "@emotion/styled";
import { useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import {
  BodyStyles,
  Button,
  Card,
  Combobox,
  ContextMenu,
  Heading,
  Modal,
  Table,
  TextInput,
} from "@smart/itops-ui-dom";
import { useLazyLoadTeams } from "@smart/manage-gql-client-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

import { ActAs } from "./act-as";
import { useFormColumns } from "./columns";
import { CreatingFormModal } from "./creating";
import { useFormData } from "./data";
import { DeletingFormModal } from "./deleting";
import { DuplicatingFormModal } from "./duplicating";
import { SetupModal } from "./setup";
import { Banner as BannerComponent } from "../../shared";
import { NavRight } from "../shared/nav-right";
import { GqlForm, GqlSetting } from "../types";

const Banner = styled(BannerComponent)`
  padding: 0;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 1rem;
  gap: 2rem;
  width: 100%;

  .controls {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: flex-start;
    font-size: ${(props) => props.theme.fontSize.base};

    width: 100%;
    max-width: ${(props) => props.theme.breakPoints.maximum}px;

    .filters {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      align-items: center;
      flex: 1;
    }

    .search {
      flex: 0 1 20rem;
    }

    .category {
      flex: 0 1 16rem;
    }

    .filter {
      min-width: 20rem;
      width: fit-content;
    }
  }
`;

const Header = styled.h1`
  font-size: ${(props) => props.theme.fontSize.subHeading};
  margin: 1.3rem 0;
  font-weight: 700;
`;

export type ListFormsProps = {
  loading?: boolean;
  forms?: GqlForm[];
  setting?: GqlSetting;
};

export const ListForms = ({ loading, forms, setting }: ListFormsProps) => {
  const [creatingForm, setCreatingForm] = useState<true>();
  const { data, filterOptions, filters } = useFormData({ forms });
  const { canActAsFirm } = useManageFormsContext();
  const [loadTeam, { data: loadedTeam, error, loading: loadingTeam }] =
    useLazyLoadTeams();
  const [errorLoadingTeam, setErrorLoadingTeam] = useState(error);
  const {
    columns,
    globalFilter,
    select,
    sort,
    deletingForm,
    setDeletingForm,
    duplicatingForm,
    setDuplicatingForm,
  } = useFormColumns();

  return (
    <>
      <BodyStyles />
      <Heading nav={<NavRight />} left={<Header>Intake Forms</Header>} />
      <Banner flagKey="builderBannerInfo" />
      <Page>
        <div className="controls">
          <div className="filters">
            <TextInput
              className="search"
              kind="round"
              icon={{ library: "lucide", name: "Search" }}
              title="Search forms"
              value={globalFilter.state}
              onChange={(e) => globalFilter.onChange(e.currentTarget.value)}
            />
            {filters.map((f) => (
              <Combobox {...f} />
            ))}

            <ContextMenu
              button={{
                icon: { library: "lucide", name: "Filter" },
                kind: "input",
                variant: "action",
                title: "Filter forms",
              }}
              placement="bottom-start"
              items={filterOptions}
            />
          </div>
          {canActAsFirm && (
            <ActAs loadTeam={loadTeam} loadedTeam={loadedTeam} />
          )}
          <Button
            icon={{ library: "lucide", name: "Plus" }}
            variant="save"
            text="Create form"
            onClick={() => setCreatingForm(true)}
          />
        </div>

        <Card breakPoint="maximum">
          <Table
            data={data}
            columns={columns}
            updating={loading || loadingTeam}
            loadingRows={loading && !forms ? 3 : undefined}
            getRowId={(row) => row.uri}
            select={select}
            sort={sort}
            globalFilter={globalFilter}
            empty={
              <>
                <Icon
                  className="empty-icon"
                  library="lucide"
                  name="FilePenLine"
                  size={30}
                  stroke={1.6}
                />
                <p>No forms {forms?.length ? "found" : "available"}.</p>
                <p>To get started, create a form above.</p>
              </>
            }
          />
        </Card>
        <CreatingFormModal
          creatingForm={creatingForm}
          forms={forms || []}
          setting={setting}
          onClose={() => setCreatingForm(undefined)}
        />
        <DeletingFormModal
          deletingForm={deletingForm}
          onClose={() => setDeletingForm(undefined)}
        />
        <DuplicatingFormModal
          duplicatingForm={duplicatingForm}
          forms={forms || []}
          onClose={() => setDuplicatingForm(undefined)}
        />
        <SetupModal loading={loading} setting={setting} />
        <Modal
          header={{
            icon: { library: "lucide", name: "Info", variant: "action" },
            text: "Error loading firm",
          }}
          onClose={() => setErrorLoadingTeam(undefined)}
          open={!!errorLoadingTeam}
          footer={{
            right: [
              {
                text: "OK",
                variant: "save",
                onClick: () => setErrorLoadingTeam(undefined),
              },
            ],
          }}
        >
          <p>{`This error occurred when trying to switch firms:\n${errorLoadingTeam}`}</p>
        </Modal>
      </Page>
    </>
  );
};
