import styled from "@emotion/styled";

import { skeleton } from "../../utils";

export const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;

  table {
    border-collapse: collapse;
    font-size: ${(props) => props.theme.fontSize.base};
    min-width: 100%;

    thead {
      position: relative;

      tr.overlay {
        td {
          padding: 0;
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r25};
      color: ${(props) => props.theme.scheme.grey.r60};
      font-size: ${(props) => props.theme.fontSize.small};
      font-weight: 500;
      text-align: left;
      padding: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 0;

      .table-header {
        display: flex;
        align-items: center;
        width: 100%;

        .table-header-title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .table-header-sort {
          background: ${(props) => props.theme.scheme.grey.r35};
          border-radius: 0.4rem;
          color: ${(props) => props.theme.scheme.grey.r80};
          padding: 0.2rem;
          opacity: 0;
          transition:
            opacity 0.4s ease,
            transform 0.4s ease;
        }
      }

      &.sortable {
        cursor: pointer;
      }
      &[aria-sort] {
        .table-header .table-header-sort {
          opacity: 1;
        }
      }
      &[aria-sort="descending"] .table-header .table-header-sort {
        transform: rotate(180deg);
      }
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          --background: ${(props) => props.theme.scheme.grey.r5};
        }

        &[aria-selected="true"] {
          --background: ${(props) => props.theme.scheme.blue.r10};
        }
      }
    }

    td {
      background-color: var(--background);
      border-bottom: 1px solid ${(props) => props.theme.scheme.grey.r25};
      padding: 1.6rem;

      &.display.selectable {
        cursor: pointer;
      }
      &.action {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .cell-skeleton {
        display: block;
        border-radius: 0.8rem;
        width: 70%;
        ${skeleton(3, 0)}
      }

      .cell-empty {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        .empty-icon {
          color: ${(props) => props.theme.scheme.grey.r60};
          margin: 1rem 0;
        }

        p {
          margin: 0.2rem 0;
        }
      }
    }
  }
`;
