import { rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { formCategoryLabel, globalTeamUri } from "@smart/bridge-types-basic";
import { useStorage } from "@smart/itops-hooks-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { extractId } from "@smart/itops-types-basic";
import {
  MultipleLabel,
  Pill,
  PillProps,
  TableProps,
} from "@smart/itops-ui-dom";
import { formatTime } from "@smart/itops-utils-basic";

import { FormMenu } from "./menu";
import { GqlForm } from "../types";

const filterSchema = z.string();
const sortSchema = z.array(z.object({ id: z.string(), desc: z.boolean() }));

const fuzzyFilter: FilterFn<GqlForm> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: 2 });
  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

export const useFormColumns = () => {
  const [filterValue, setFilterValue] = useStorage({
    defaultValue: "",
    key: "form-builder-list-filter",
    schema: filterSchema,
    storage: "session",
  });
  const [columnSort, setColumnSort] = useStorage({
    defaultValue: [{ id: "updatedAt", desc: true }],
    key: "form-builder-list-sort",
    schema: sortSchema,
    storage: "local",
  });
  const [deletingForm, setDeletingForm] = useState<GqlForm>();
  const [duplicatingForm, setDuplicatingForm] = useState<GqlForm>();

  const navigate = useNavigate();

  const columns = useMemo(() => {
    const col = createColumnHelper<GqlForm>();

    return [
      col.accessor("formTitle", { header: "Name" }),
      col.accessor("formCategory", {
        header: "Form Type",
        enableGlobalFilter: false,
        cell: ({ getValue }) => formCategoryLabel[getValue()],
      }),
      col.accessor("matterTypeList", {
        header: "Matter Type",
        cell: ({ row }) => (
          <MultipleLabel
            labels={[
              row.original.matterTypeNames,
              row.original.matterTypeLocations,
            ]}
            maxVisibleItems={1}
          />
        ),
      }),
      col.accessor("updatedAt", {
        header: "Last Updated",
        enableGlobalFilter: false,
        sortDescFirst: true,
        cell: ({ getValue }) => formatTime(getValue()),
      }),
      col.accessor("createdBy", {
        header: "Created By",
        enableGlobalFilter: false,
        cell: (c) => {
          const providerInfo = useProviderInfo();
          return c.row.original.teamUri === globalTeamUri
            ? providerInfo?.label
            : c.getValue();
        },
      }),
      col.accessor("active", {
        header: "Status",
        enableGlobalFilter: false,
        sortUndefined: -1,
        cell: (c) => {
          let props: PillProps = { text: "Inactive", variant: "default" };
          if (
            c.row.original.aiUserFeedback?.status === "generating" ||
            c.row.original.aiUserFeedback?.status === "generated"
          )
            props = {
              text: "Draft",
              icon: { library: "lucide", name: "Sparkles" },
              variant: "highlightOrange",
            };
          if (c.row.original.active)
            props = { text: "Active", variant: "success" };

          return <Pill {...props} />;
        },
      }),
      col.display({
        id: "menu",
        meta: { action: true },
        cell: ({ row }) => (
          <FormMenu
            form={row.original}
            setDeletingForm={setDeletingForm}
            setDuplicatingForm={setDuplicatingForm}
          />
        ),
      }),
    ];
  }, []);

  const globalFilter: TableProps<GqlForm>["globalFilter"] = {
    state: filterValue,
    onChange: setFilterValue,
    fn: fuzzyFilter,
  };

  const select: TableProps<GqlForm>["select"] = {
    state: {},
    onChange: (selected) => {
      const value = typeof selected === "function" ? selected({}) : selected;
      const uri = Object.keys(value)[0];
      navigate(`/embedded/builder/${extractId(uri)}`);
    },
  };

  const sort: TableProps<GqlForm>["sort"] = {
    state: columnSort,
    onChange: setColumnSort,
  };

  return {
    columns,
    globalFilter,
    select,
    sort,
    deletingForm,
    setDeletingForm,
    duplicatingForm,
    setDuplicatingForm,
  };
};
