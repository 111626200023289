import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Icon } from "@smart/itops-components-dom";
import { skeleton } from "@smart/itops-ui-dom";

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.6rem;
`;

const NavItemWrapper = styled.div<{
  isCurrent: boolean;
  isLoading: boolean;
  isDragging: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isCurrent
      ? props.theme.scheme.grey.r20
      : props.theme.scheme.grey.r10};
  border-radius: ${(props) => props.theme.baseUnit * 0.5}rem;
  padding: ${(props) => props.theme.baseUnit * 1.2}rem
    ${(props) => props.theme.baseUnit}rem;
  cursor: pointer;
  border: 2px solid transparent;
  ${(props) => props.isLoading && skeleton(0)(props)}

  :hover {
    background-color: ${(props) => props.theme.scheme.grey.r20};
    border: 2px solid
      ${(props) =>
        props.isDragging ? props.theme.scheme.blue.r100 : "transparent"};
  }

  .item-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${(props) => props.theme.baseUnit * 2.4}rem;
    height: ${(props) => props.theme.baseUnit * 2.4}rem;
    line-height: ${(props) => props.theme.baseUnit * 2.4}rem;

    color: ${(props) =>
      props.isCurrent
        ? props.theme.scheme.grey.r0
        : props.theme.scheme.grey.r100};
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 500;
    background-color: ${(props) =>
      props.isCurrent
        ? props.theme.scheme.blue.r100
        : props.theme.scheme.grey.r30};
    border-radius: ${(props) => props.theme.baseUnit * 0.5}rem;

    margin-right: ${(props) => props.theme.baseUnit}rem;
  }

  .item-text {
    flex: 1 1;
    color: ${(props) =>
      props.isCurrent
        ? props.theme.scheme.blue.r100
        : props.theme.scheme.grey.r100};
    font-size: ${(props) => props.theme.fontSize.emphasis};
    margin: 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .item-arrow {
    color: ${(props) => props.theme.scheme.blue.r100};
    margin-left: ${(props) => props.theme.baseUnit}rem;
  }
`;

type NavItemProps = {
  section: {
    uri: string;
    values?: {
      title: string;
    };
  };
  index: number | ReactNode;
  currentSectionUri: string;
  setCurrentSectionUri: (uri: string) => void;
  loading: boolean;
  isDraggingItem: boolean;
  setDroppedOnSectionUri: (sectionUri: string | undefined) => void;
  setIsValidSection: (isValid: boolean) => void;
};

const NavItem = ({
  section,
  index,
  currentSectionUri,
  setCurrentSectionUri,
  loading,
  isDraggingItem,
  setDroppedOnSectionUri,
  setIsValidSection,
}: NavItemProps) => {
  const isCurrent = section.uri === currentSectionUri;

  return (
    <NavItemWrapper
      data-testid={`nav-item-${section.uri}`}
      isCurrent={isCurrent}
      isLoading={loading}
      onMouseOver={() => isDraggingItem && setIsValidSection(!isCurrent)}
      onMouseDown={() => setCurrentSectionUri(section.uri)}
      isDragging={!isCurrent && isDraggingItem}
      onMouseUp={() => {
        if (isCurrent || !isDraggingItem) return;
        setDroppedOnSectionUri(section.uri);
      }}
    >
      <div className="item-index">
        {typeof index === "number" ? index + 1 : index}
      </div>
      <p className="item-text">{section.values?.title || "Section"}</p>
      {isCurrent && (
        <Icon
          className="item-arrow"
          library="lucide"
          name="ArrowRight"
          dataTestId="current-section-arrow"
        />
      )}
    </NavItemWrapper>
  );
};

export { NavItem, NavItemProps };
