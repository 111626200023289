// Files contain tailwind class names should have tsx extension for tailwind to scan

import { clsx } from "clsx";

const base =
  "relative flex justify-center items-center gap-[0.8rem] px-[1.6rem] py-[0.9rem] rounded focus-visible:outline-none disabled:cursor-not-allowed";
export const focusBorder =
  "focus:before:absolute focus:before:inset-[-0.4rem] focus:before:border focus:before:border-blue-560 focus:before:rounded focus:before:border-solid";

const primaryDefaultBase = clsx(
  base,
  "text-white bg-blue-560 border-solid border border-blue-560",
);
const primaryDefaultFocus = clsx(focusBorder, "focus:bg-blue-460");
const primaryDefaultHover = "hover:bg-blue-460";
const primaryDefaultActive = "active:bg-blue-660";
const primaryDefaultDisabled = "disabled:bg-black/5 disabled:text-neutral-400";

const primarySubtleBase = clsx(
  base,
  "text-blue-560 bg-white border-solid border border-blue-360",
);
const primarySubtleFocus = clsx(
  focusBorder,
  "focus:border-blue-560 focus:bg-blue-60",
);
const primarySubtleHover = "hover:border-blue-560 hover:bg-blue-60";
const primarySubtleActive =
  "active:bg-blue-660 active:border-blue-560 active:text-white";
const primarySubtleDisabled =
  "disabled:bg-white disabled:border-neutral-400 disabled:text-neutral-400";

const secondaryDefaultBase = clsx(
  base,
  "text-neutral-850 bg-white border-solid border border-neutral-400",
);
const secondaryDefaultFocus = clsx(focusBorder, "focus:bg-neutral-50");
const secondaryDefaultHover = "hover:bg-neutral-50";
const secondaryDefaultActive =
  "active:bg-neutral-100 active:border-neutral-100";
const secondaryDefaultDisabled =
  "disabled:bg-white disabled:border-neutral-400 disabled:text-neutral-400";

const secondarySubtleBase = clsx(base, "text-neutral-850");
const secondarySubtleFocus = clsx(focusBorder, "focus:bg-neutral-50");
const secondarySubtleHover = "hover:bg-neutral-50";
const secondarySubtleActive = "active:bg-neutral-100";
const secondarySubtleDisabled = "disabled:bg-white disabled:text-neutral-400";

const highlightBase = clsx(base, "text-blue-560 bg-white");
const highlightFocus = clsx(focusBorder, "focus:bg-blue-60");
const highlightHover = "hover:bg-blue-60";
const highlightActive = "active:bg-blue-660 active:text-white";
const highlightDisabled = "disabled:bg-white disabled:text-neutral-400";

const invertedDefaultBase = clsx(
  base,
  "text-white border-solid border border-white/40",
);
const invertedDefaultFocus = clsx(focusBorder, "focus:bg-white/10");
const invertedDefaultHover = "hover:bg-white/10";
const invertedDefaultActive = "active:bg-white/20 active:border-white/0";
const invertedDefaultDisabled =
  "disabled:text-white/40 disabled:bg-white/0 disabled:border-white/40";

const invertedSubtleBase = clsx(
  base,
  "text-white border-solid border border-white/0",
);
const invertedSubtleFocus = clsx(focusBorder, "focus:bg-white/10");
const invertedSubtleHover = "hover:bg-white/10";
const invertedSubtleActive = "active:bg-white/20";
const invertedSubtleDisabled = "disabled:text-white/40 disabled:bg-white/0";

const inlineDefaultBase = clsx(
  base,
  "text-neutral-850 px-[1.2rem] py-[0.125rem] rounded-small border-solid border border-neutral-400",
);
const inlineDefaultFocus = clsx(
  focusBorder,
  "focus:bg-neutral-50",
  "focus:before:rounded-small",
);
const inlineDefaultHover = "hover:bg-neutral-50";
const inlineDefaultActive = "active:bg-neutral-100 active:border-neutral-100";
const inlineDefaultDisabled =
  "disabled:text-neutral-400 disabled:bg-white/0 disabled:border-neutral-400";

const inlineSubtleBase = clsx(inlineDefaultBase, "border-white/0");
const inlineSubtleFocus = inlineDefaultFocus;
const inlineSubtleHover = inlineDefaultHover;
const inlineSubtleActive = inlineDefaultActive;
const inlineSubtleDisabled =
  "disabled:text-neutral-400 disabled:bg-white/0 disabled:border-white/0";

export type ButtonVariant =
  | "primaryDefault"
  | "primarySubtle"
  | "secondaryDefault"
  | "secondarySubtle"
  | "highlight"
  | "invertedDefault"
  | "invertedSubtle"
  | "inlineDefault"
  | "inlineSubtle";

export const getVariantClassNames = ({
  loading,
  variant,
}: {
  loading: boolean | undefined;
  variant: ButtonVariant;
}) => {
  const buttonVariants = {
    primaryDefault: clsx(
      primaryDefaultBase,
      !loading && [
        primaryDefaultFocus,
        primaryDefaultHover,
        primaryDefaultActive,
        primaryDefaultDisabled,
      ],
    ),
    primarySubtle: clsx(
      primarySubtleBase,
      !loading && [
        primarySubtleFocus,
        primarySubtleHover,
        primarySubtleActive,
        primarySubtleDisabled,
      ],
    ),
    secondaryDefault: clsx(
      secondaryDefaultBase,
      !loading && [
        secondaryDefaultFocus,
        secondaryDefaultHover,
        secondaryDefaultActive,
        secondaryDefaultDisabled,
      ],
    ),
    secondarySubtle: clsx(
      secondarySubtleBase,
      !loading && [
        secondarySubtleFocus,
        secondarySubtleHover,
        secondarySubtleActive,
        secondarySubtleDisabled,
      ],
    ),
    highlight: clsx(
      highlightBase,
      !loading && [
        highlightFocus,
        highlightHover,
        highlightActive,
        highlightDisabled,
      ],
    ),
    invertedDefault: clsx(
      invertedDefaultBase,
      !loading && [
        invertedDefaultFocus,
        invertedDefaultHover,
        invertedDefaultActive,
        invertedDefaultDisabled,
      ],
    ),
    invertedSubtle: clsx(
      invertedSubtleBase,
      !loading && [
        invertedSubtleFocus,
        invertedSubtleHover,
        invertedSubtleActive,
        invertedSubtleDisabled,
      ],
    ),
    inlineDefault: clsx(
      inlineDefaultBase,
      !loading && [
        inlineDefaultFocus,
        inlineDefaultHover,
        inlineDefaultActive,
        inlineDefaultDisabled,
      ],
    ),
    inlineSubtle: clsx(
      inlineSubtleBase,
      !loading && [
        inlineSubtleFocus,
        inlineSubtleHover,
        inlineSubtleActive,
        inlineSubtleDisabled,
      ],
    ),
  };

  return buttonVariants[variant];
};

export const getSingleIconClassNames = (variant: ButtonVariant) =>
  ["inlineDefault", "inlineSubtle"].includes(variant)
    ? "p-[0.325rem]"
    : "p-[1.1rem]";
