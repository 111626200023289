import { faFloppyDisk as lightFloppyDisk } from "@awesome.me/kit-81e892e0ca/icons/classic/light";
import {
  faArrowDown as regularArrowDown,
  faArrowLeft as regularArrowLeft,
  faArrowUp as regularArrowUp,
  faAsterisk as regularAsterisk,
  faCircleExclamation as regularCircleExclamation,
  faCircleInfo as regularCircleInfo,
  faAngleDown as regularAngleDown,
  faBold as regularBold,
  faComment as regularComment,
  faItalic as regularItalic,
  faLink as regularLink,
  faMagnifyingGlass as regularMagnifyingGlass,
  faPaperPlaneTop as regularPlaneTop,
  faQrcode as regularQrcode,
  faShare as regularShare,
  faXmark as regularXmark,
  faPencil as regularPencil,
  faSparkles as regularSparkles,
  faFilePen as regularFilePen,
} from "@awesome.me/kit-81e892e0ca/icons/classic/regular";
import {
  faBell as solidBell,
  faCheck as solidCheck,
  faChevronRight as solidChevronRight,
  faCircleCheck as solidCircleCheck,
  faCircleInfo as solidCircleInfo,
  faComment as solidComment,
  faDiamondExclamation as solidDiamondExclamation,
  faEllipsis as solidEllipsis,
  faFloppyDisk as solidFloppyDisk,
  faPaperPlaneTop as solidPaperPlaneTop,
  faShare as solidShare,
  faShieldCheck as solidShieldCheck,
  faSquareA as solidSquareA,
  faTriangleExclamation as solidTriangleExclamation,
  faQrcode as solidQrcode,
  faXmark as solidXmark,
  faXmarkLarge as solidXmarkLarge,
  faSave as solidSave,
  faTrashCan as solidTrashCan,
  faContactCard as solidContactCard,
} from "@awesome.me/kit-81e892e0ca/icons/classic/solid";

export const availableIcons = {
  lightFloppyDisk,
  regularArrowDown,
  regularArrowLeft,
  regularArrowUp,
  regularAsterisk,
  regularBold,
  regularCircleExclamation,
  regularCircleInfo,
  regularComment,
  regularItalic,
  regularLink,
  regularMagnifyingGlass,
  regularXmark,
  regularAngleDown,
  regularPencil,
  regularSparkles,
  regularQrcode,
  regularPlaneTop,
  regularShare,
  regularFilePen,
  solidBell,
  solidComment,
  solidCheck,
  solidFloppyDisk,
  solidShieldCheck,
  solidSquareA,
  solidXmark,
  solidXmarkLarge,
  solidEllipsis,
  solidChevronRight,
  solidCircleCheck,
  solidCircleInfo,
  solidDiamondExclamation,
  solidTriangleExclamation,
  solidQrcode,
  solidPaperPlaneTop,
  solidShare,
  solidSave,
  solidContactCard,
  solidTrashCan,
};

export type IconName = keyof typeof availableIcons | "archie";
