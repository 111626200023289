import { ActingAsLabel } from "./acting-as-label";
import { WindowCloser } from "./window-closer";

export const NavRight = () => (
  <>
    <div style={{ flex: 1 }} />
    <ActingAsLabel />
    <WindowCloser />
  </>
);
