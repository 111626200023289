import { useMemo } from "react";
import { Schema, z } from "zod";

import {
  FormCategory,
  formCategoryLabel,
  globalTeamUri,
} from "@smart/bridge-types-basic";
import { useStorage } from "@smart/itops-hooks-dom";
import { loadLocale } from "@smart/itops-locale-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { ComboboxProps, ContextMenuProps } from "@smart/itops-ui-dom";
import { unique } from "@smart/itops-utils-basic";

import { GqlForm } from "../types";

const categorySchema = z.enum(["lead", "matter"]).optional();
const filtersSchema = z.array(z.string());
const locationsSchema = z.array(z.string());
const matterTypesSchema = z.array(
  z.object({ name: z.string(), category: z.string() }),
);
const creatorsSchema = z.array(z.string());

export const useFormData = ({ forms }: { forms?: GqlForm[] }) => {
  const providerInfo = useProviderInfo();
  const { terms } = loadLocale();
  const [category, setCategory] = useStorage({
    defaultValue: undefined,
    key: "form-builder-list-category",
    schema: categorySchema,
    storage: "local",
  });

  const [filters, setFilters] = useStorage({
    defaultValue: [],
    key: "form-builder-list-filters",
    schema: filtersSchema as Schema<(keyof typeof filterSets)[]>,
    storage: "session",
  });
  const [locations, setLocations] = useStorage({
    defaultValue: [],
    key: "form-builder-list-locations",
    schema: locationsSchema,
    storage: "session",
  });
  const [matterTypes, setMatterTypes] = useStorage({
    defaultValue: [],
    key: "form-builder-list-matterTypes",
    schema: matterTypesSchema,
    storage: "session",
  });
  const [creators, setCreators] = useStorage({
    defaultValue: [],
    key: "form-builder-list-creators",
    schema: creatorsSchema,
    storage: "session",
  });

  const byCategory: ComboboxProps<"all" | FormCategory, false, true> & {
    key: string;
  } = {
    key: "category",
    id: "category",
    name: "category",
    title: "Filter by category",
    options: ["all", "lead", "matter"],
    getOptionLabel: (o) =>
      `${o === "all" ? "All" : formCategoryLabel[o]} forms`,
    value: category || "all",
    onChange: (_, v) => setCategory(v === "all" ? undefined : v),
    disableClearable: true,
    className: "category",
    kind: "round",
  };

  const locationOptions = useMemo(
    () => unique(forms?.flatMap((f) => f.matterTypeLocations) || []).sort(),
    [forms],
  );
  const byLocation: ComboboxProps<string, true, true> & { key: string } = {
    key: "location",
    id: "location",
    name: "location",
    title: `Filter by ${terms.state.name}`,
    options: locationOptions,
    value: locations,
    multiple: true,
    maxItems: 2,
    disableClearable: true,
    onChange: (_, v) => setLocations(v),
    placeholder: terms.state.title,
    className: "filter",
    kind: "round",
  };

  const matterTypeOptions = useMemo(
    () =>
      unique(
        forms?.flatMap((f) =>
          f.rawForm.values.matterTypes.map((m) => `${m.category}|||${m.name}`),
        ) || [],
      )
        .sort()
        .map((m) => {
          const parts = m.split("|||");
          return { category: parts[0], name: parts[1] };
        }),
    [forms],
  );
  const byMatterType: ComboboxProps<
    { name: string; category: string },
    true,
    true
  > & { key: string } = {
    key: "matterType",
    id: "matterType",
    name: "matterType",
    title: "Filter by matter type",
    options: matterTypeOptions,
    value: matterTypes,
    multiple: true,
    maxItems: 2,
    groupBy: (o) => o.category,
    getOptionLabel: (o) => o.name,
    onChange: (_, v) => setMatterTypes(v),
    disableClearable: true,
    placeholder: "Types",
    className: "filter",
    kind: "round",
  };

  const creatorOptions = useMemo(
    () =>
      unique(
        forms?.map((f) => f.createdBy || providerInfo?.label || "") || [],
      ).sort(),
    [forms],
  );
  const byCreator: ComboboxProps<string, true, true> & { key: string } = {
    id: "creator",
    name: "creator",
    title: "Filter by creator",
    key: "creator",
    options: creatorOptions,
    value: creators,
    multiple: true,
    maxItems: 2,
    onChange: (_, v) => setCreators(v),
    disableClearable: true,
    placeholder: "Creators",
    className: "filter",
    kind: "round",
  };

  const filterSets = { byLocation, byMatterType, byCreator };

  const onToggleFilter = (key: keyof typeof filterSets) => () => {
    if (filters.includes(key)) {
      setFilters((f) => f.filter((k) => k !== key));
      ({
        byLocation: setLocations,
        byMatterType: setMatterTypes,
        byCreator: setCreators,
      })[key]([]);
    } else {
      setFilters((f) => [...f, key]);
    }
  };

  const filterOptions: ContextMenuProps["items"] = [
    {
      text: `By ${terms.state.name}`,
      checked: filters.includes("byLocation"),
      onClick: onToggleFilter("byLocation"),
    },
    {
      text: "By matter type",
      checked: filters.includes("byMatterType"),
      onClick: onToggleFilter("byMatterType"),
    },
    {
      text: "By creator",
      checked: filters.includes("byCreator"),
      onClick: onToggleFilter("byCreator"),
    },
  ];

  const data = useMemo(() => {
    if (!forms) return [];

    return forms.filter((f) => {
      if (category && f.formCategory !== category) return false;

      if (creators.length) {
        const isCreator = creators.find((c) => c === f.createdBy);
        const isSmokeball =
          creators.includes(providerInfo?.label || "") &&
          f.teamUri === globalTeamUri;

        if (!isCreator && !isSmokeball) return false;
      }

      if (locations.length) {
        if (
          !locations.find((l) => f.matterTypeLocations.some((mtl) => mtl === l))
        )
          return false;
      }

      if (matterTypes.length) {
        if (
          !matterTypes.find((mt) =>
            f.rawForm.values.matterTypes.some(
              (m) => m.category === mt.category && m.name === mt.name,
            ),
          )
        )
          return false;
      }

      return true;
    });
  }, [forms, category, creators, locations, matterTypes]);

  return {
    data,
    filterOptions,
    filters: [
      byCategory,
      ...filters.map((f) => filterSets[f]),
    ] as ComboboxProps<any, boolean, boolean>[],
  };
};
