import styled from "@emotion/styled";
import { position } from "polished";

export const NavigationWrapper = styled.div`
  width: 31rem;
  padding: 2rem;
  overflow: auto;
  position: relative;
  display: flex;
  flex-flow: column;
  font-size: ${(props) => props.theme.fontSize.base};

  &[aria-disabled="true"] {
    &:after {
      content: "";
      ${position("absolute", 0)};
      cursor: wait;
    }
  }

  > h2 {
    color: ${(props) => props.theme.scheme.grey.r60};
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 600;
    margin: 1.2rem 0;
  }
`;
