import styled from "@emotion/styled";

import { Icon } from "@smart/itops-components-dom";
import { Toggle, Tooltip } from "@smart/itops-ui-dom";

const FieldValidationToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .toggle-label {
    font-size: ${(props) => props.theme.fontSize.base};
  }

  .alert-icon {
    display: flex;
    align-items: center;
  }
`;

type FieldValidationToggleProps = {
  toggled: boolean;
  onClick: () => void;
};

export const FieldValidationToggle = ({
  toggled,
  onClick,
}: FieldValidationToggleProps) => (
  <FieldValidationToggleWrapper>
    <Tooltip
      tooltipText="Turn off field validation to preview your form without errors."
      placement="top"
      offsetBy={7}
    >
      <Icon
        library="lucide"
        name="AlertCircle"
        size={13}
        className="alert-icon"
      />
    </Tooltip>
    <Toggle size={3.5} toggled={toggled} onClick={onClick} />
    <div className="toggle-label">Field validation</div>
  </FieldValidationToggleWrapper>
);
